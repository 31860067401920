// Define all style variables here.

// Colours.
$white: #ffffff;
$black: #202020;
$offBlack : #1f1f1f;

$darkGreen: #003632;
$lightGreen: #006b64;
$primary: $lightGreen;

$low-light-10 : #ccdddc;
$low-light-20 : #aac6c4;
$low-light-30 : #80aaa7;
$low-light-40 : #558d8a;
$low-light-50 : #2a716c;
$low-light : #00544f;
$low-light-60 : #004642;
$low-light-70 : #003835;
$low-light-80 : #002a28;
$low-light-90 : #001c1a;
$low-light-100 : #001110;

$yellow-10 : #ffffcc;
$yellow-20 : #FFFFAA;
$yellow-30 : #FEFF80;
$yellow-40 : #feff55;
$yellow-50 : #feff2a;
$yellow : #FEFF00;
$yellow-60 : #d4d500;
$yellow-70 : #a9aa00;
$yellow-80 : #7F8000;
$yellow-90 : #555500;
$yellow-100 : #333300;

$blue-10 : #cedfef;
$blue-20 : #adcae4;
$blue-30 : #84b0d6;
$blue-40 : #5b96c8;
$blue-50 : #327bbb;
$blue : #0961AD;
$blue-60 : #085190;
$blue-70 : #064173;
$blue-80 : #053157;
$blue-90 : #03203a;
$blue-100 : #021323;

$green-10 : #ccecea;
$green-20 : #aae0dc;
$green-30 : #80d0cb;
$green-40 : #55c0b9;
$green-50 : #2AB1A8;
$green : #00A196;
$green-60 : #00867d;
// $green-70 : #006b64;
$green-80 : #00514b;
// $green-90 : #003632;
$green-100 : #00201e;

$gray-10 : #f4f6f6;
$gray-20 : #ecf0f0;
$gray-30 : #E3E9E9;
$gray-40 : #DAE1E1;
$gray-50 : #D0DADA;
$gray    : #c7d2d2;
$gray-60 : #a6afaf;
$gray-70 : #858c8c;
$gray-80 : #646969;
$gray-90 : #424646;
$gray-100 : #282a2a;

$greenGradient : linear-gradient( 90deg, $lightGreen 33.19%,#00514b 55.56%,$darkGreen 84.8% );
$lightGreenGradient : linear-gradient( 0deg ,$low-light-20 , $low-light-10 );
$blueGradient : linear-gradient( 90deg, $blue-60 0%, $blue-70 98%);


// Fonts.
@import url("https://use.typekit.net/uoy5byi.css");
$font: "acumin-pro", sans-serif;

// Media querys.
$sm: "(min-width: 575px)";
$md: "(min-width: 768px)";
$lg: "(min-width: 992px)";
$xlg: "(min-width: 1025px)";
$xl: "(min-width: 1200px)";
$large: "(min-width: 1366px)";
$desktop: "(min-width: 1600px)";
$extra: "(min-width: 1920px)";

// Misc.
$ease: all 0.3s ease-in-out;
