// Default element styles and main containers.

@import "vars";

* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
	color: $offBlack;
	font-family: $font;
	font-weight: 400;
	font-size: 14px;
	-webkit-appearance: none;
	line-height: 1.4;
	letter-spacing: 1px;
}

body,
html {
	height: 100%;
	background-color: $gray-10;
}
body{
	display: flex;
  	flex-direction: column;
	main{
		flex: 1;
	}
	&.modal-open{
		overflow: hidden;
	}
}
// body,
// main {
// 	overflow-x: hidden;
// }

body.page-id-925,
body.page-id-925 main,
body.page-id-1691,
body.page-id-1691 main,
body.page-id-1687,
body.page-id-1687 main,
body.single-product,
body.single-product main {
	overflow: visible;
}

p {
	&:not(:last-of-type) {
		margin-bottom: 20px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.1;
	font-weight: 600;
	font-family: $font;
}

h1 {
	font-size: 25px;
}

h2 {
	font-size: 20px;
}

h3,
h4 {
	font-size: 18px;
}

h5,
h6 {
	font-size: 16px;
}

a {
	transition: $ease;
	text-decoration: none;
}

ul,ol{
	padding-left: 24px;
	margin-bottom: 20px;
}
span,
u,
b,
strong,
i,
em,
blockquote,
a {
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
}

i,
em,
blockquote {
	font-style: italic;
}

b,
strong {
	font-weight: 600;
}

address {
	font-style: normal;
	font-family: $font;
}

img {
	object-fit: cover;
	height: auto;
}
.h1, h1{
	font-size: 36px;
	@media #{$sm}{
		font-size: 45px;
	}
	@media #{$lg}{
		font-size: 48px;
	}
	// @media #{$xl}{
	// 	font-size: 61px;
	// }
}
.h2, h2{
	font-size: 32px;
	@media #{$sm}{
		font-size: 40px;
	}
	@media #{$lg}{
		font-size: 44px;
	}
	// @media #{$xl}{
	// 	font-size: 49px;
	// }
}
.h3, h3{
	font-size: 28px;
	@media #{$sm}{
		font-size: 34px;
	}
	@media #{$lg}{
		font-size: 39px;
	}
}
.h4, h4{
	font-size: 24px;
	@media #{$sm}{
		font-size: 26px;
	}
	@media #{$lg}{
		font-size: 31px;
	}
}
.h5, h5{
	font-size: 22px;
	@media #{$sm}{
		font-size: 25px;
	}
}
.h6 , h6{
	font-size: 20px;
}

.section-heading{
	margin-bottom: 24px;
	position: relative;
	@media #{$md}{
		margin-bottom: 48px;
	}
	@media (max-width: 640px){
		text-align: center;
	}
	.tag-line{
		color : $blue;
		font-size: 16px;
		line-height: 1;
		margin-bottom: 16px;
		@media #{$md}{
			font-size: 20px;
			margin-bottom: 24px;
		}
	}
	&.with-cta{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
		@media #{$md}{
			flex-direction: row;
		}
		.btn{
			margin-top: 20px;
			@media #{$md}{
				margin-top: 0;
			}	
			@media (max-width: 575px){
				width: 100%;
			}
		}
	}
}
.mt-0{
	margin-top: 0 !important;
}
.mb-0{
	margin-bottom: 0 !important;
}
.mt-40{
	margin-top: 20px !important;
	@media #{$md}{
		margin-top: 40px !important;
	}
}
.pagination-wrapper{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	.prev-btn{
		&.btn{
			&.icon-btn{
				color: $low-light;
				font-size: 18px;
				text-transform: capitalize;
				position: relative;
				&:before{
					content: '';
					position: absolute;
					width: 35px;
					height: 35px;
					border-radius: 50%;
					left: -5px;
					top: 50%;
					transform: translateY(-50%);
					transition: 0.4s;
					background-color: rgba($darkGreen, 0.0);
				}
				&:hover{
					&:before{
						transition: 0.4s;
						background-color: rgba($darkGreen, 0.2);
					}
					svg{
						transform: translateX(-5px);
					}
				}
				svg{
					fill: $low-light;
					margin-left: 0;
					margin-right: 16px;
					position: relative;
					transition: 0.4s;
					transform: translateX(0px);
				}
				&.disabled{opacity: 0.5;pointer-events: none;}
				span{
					@media (max-width: 767px){
						display: none;
					}
				}
			}
		}
	}
	.next-btn{
		&.btn{
			&.icon-btn{
				color: $low-light;
				font-size: 18px;
				text-transform: capitalize;
				position: relative;
				&:before{
					content: '';
					position: absolute;
					width: 35px;
					height: 35px;
					border-radius: 50%;
					right: -5px;
					top: 50%;
					transform: translateY(-50%);
					transition: 0.4s;
					background-color: rgba($darkGreen, 0.0);
				}
				&:hover{
					&:before{
						transition: 0.4s;
						background-color: rgba($darkGreen, 0.2);
					}
					svg{
						transform: translateX(5px);
					}
				}
				svg{
					fill: $low-light;
					margin-left: 16px;
					position: relative;
					transition: 0.4s;
					transform: translateX(0px);
					@media (max-width: 767px){
						height: 30px;
						width: 30px;
					}
				}
				&.disabled{opacity: 0.5;pointer-events: none;}
				span{
					@media (max-width: 767px){
						display: none;
					}
				}
			}
		}
	}
	.numbers{		
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		list-style: none;
		gap: 8px;			
		a{
			height: 40px;
			width: 40px;
			border-radius: 50%;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			background-color: transparent;
			font-size: 18px;
			line-height: 40px;
			color: $low-light;
			&:hover,&.active{
				background-color: $low-light-10;				
			}
		}		
	}		
}
.blog-menu{
	padding: 60px 47px;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
	position: sticky; 
	position: -webkit-sticky;
	top: 80px;
	border-top-left-radius: 35px;
	border-bottom-left-radius: 35px;
	background-color: $white;
	max-width: 300px;
	width: 100%;
	z-index: 9;
	@media ( max-width: 767px){
		background-color: transparent;
		width: 100%;
		max-width: 100%;
		position: static;
		border-radius: 0;
		box-shadow: none;
		margin-bottom: 30px;
		padding: 10px 30px;
	}
	ul{
		list-style: none;
		padding: 0;
		margin: 0;
		@media (max-width: 767px){
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
		li{
		  &:not(:last-child)  {
			margin-bottom: 50px;
			@media (max-width: 767px){
				margin-bottom: 0px;
			}
		  }
		  @media (max-width: 767px){
			padding: 10px;
		  }
		}
		.wrapper{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-size: 12px;
			text-transform: uppercase;
			.icon-wrapper{
				width: 24px;
				height: 24px;
				margin-right: 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				@media #{$md}{
					margin-right: 25px;
				}
				svg{
					height: 20px;
					width: 20px;
					fill: $offBlack;
				}
			}
			&:hover{
				color: $green;
				svg{
					fill: $green;
				}
			}
		}
	}
}
.campaign-page-detail-wrapper{
	position: relative;
	.campaign-side-menu-wrapper{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		max-width: 300px;
		width: 100%;
		padding-left: 30px;
		@media (max-width: 767px){
			position: static;
			max-width: 100%;
			border-radius: 0;
			padding-left: 0;
		}
	}
	.get-involve-section{
		&.content-left{
			.get-involved-content{
				@media (min-width: 768px) and (max-width: 991px){
					max-width: calc(100% - 300px);
				}
			}
		}
	}
	
}
.membership-page-layout{
	height: auto;
	&.mepr-guest-layout{
		background-color: $gray-10 !important;
		main{
			padding: 0;
		}
	}
	main#primary{
		max-width: 100%;
		background-color: $gray-10;
	}
	.mp_wrapper{
		h1,h2,h3{
			font-weight: 600;
		}
	}
	.inner-banner{
		.sub-content{
			.mepr-before-signup-form{
				display: none;
			}
			form.mepr-signup-form{
				display: none;
			}
			ul{
				list-style: none;
				padding: 0;
				margin: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				li{
					position: relative;
					margin-bottom: 30px;
					padding-left: 45px;
					&:before{
						position: absolute;
						content: '';
						height: 20px;
						width: 20px;
						background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.1 15.0186L16.15 7.96861L14.75 6.56861L9.1 12.2186L6.25 9.36861L4.85 10.7686L9.1 15.0186ZM10.5 20.4186C9.11667 20.4186 7.81667 20.1561 6.6 19.6311C5.38333 19.1061 4.325 18.3936 3.425 17.4936C2.525 16.5936 1.8125 15.5353 1.2875 14.3186C0.7625 13.1019 0.5 11.8019 0.5 10.4186C0.5 9.03528 0.7625 7.73528 1.2875 6.51861C1.8125 5.30194 2.525 4.24361 3.425 3.34361C4.325 2.44361 5.38333 1.73111 6.6 1.20611C7.81667 0.68111 9.11667 0.41861 10.5 0.41861C11.8833 0.41861 13.1833 0.68111 14.4 1.20611C15.6167 1.73111 16.675 2.44361 17.575 3.34361C18.475 4.24361 19.1875 5.30194 19.7125 6.51861C20.2375 7.73528 20.5 9.03528 20.5 10.4186C20.5 11.8019 20.2375 13.1019 19.7125 14.3186C19.1875 15.5353 18.475 16.5936 17.575 17.4936C16.675 18.3936 15.6167 19.1061 14.4 19.6311C13.1833 20.1561 11.8833 20.4186 10.5 20.4186Z' fill='white'/%3E%3C/svg%3E%0A");
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						left: 0;
						top: 5px;
					}
					p{
						font-size: 25px;
						line-height: 1.2;
						color: $white;
						font-weight: 400;
						margin: 0;
						
					}
				}
			}
		}
		+.get-in-touch-section {
			overflow: visible;
			.bg-shape-wrapper{
				display: block;
				top: 90%;
				transform: translateY(-50%);
				bottom: auto;
				left: auto;
				right: 0;
				max-width: min(900px , 60%);
				height : auto;
				margin: 0;
				display: none;
				img{
					opacity: 1;
				}
				@media #{$md}{
					display: block;
				}
			}
			&.white-bg{
				+ .support-us-section{
					background-color: transparent;
				}
			}
		}
	}
	
	.get-in-touch-section{		
		.form-heading{
			text-align: center;
		}
		.form-wrapper{
			padding: 0;
			border: 0;
			> ul{
				display: none;
			}
			.invoice-wrapper{
				display: none;
			}
		}
		.mepr-signup-form {
			.mp-address-group{
				box-shadow: none;
			}
			.form-wrapper {				
				label{
					font-size: 16px;
					font-weight: 500;
					line-height: 1.2;
					margin-bottom: 20px;
					padding: 0;
					display: inline-block !important;
					@media #{$xl}{
						font-size: 20px;
					}
				}				
				.mp-form-row-group{
					column-gap: 30px;
					@media (max-width: 767px){
						flex-direction: column;
					}
				}
				.iti--allow-dropdown .iti__flag-container, 
				.iti--separate-dial-code .iti__flag-container{
					bottom: 30px;
				}
				.mp-form-row{
					margin: 0;
					.iti__selected-flag{
						border-top-left-radius: 10px;
						border-bottom-left-radius: 10px;
						@media #{$xl}{
							border-top-left-radius: 20px;
							border-bottom-left-radius: 20px;
						}
					}
				}
				input,select{
					&.mepr-form-input {
						background-color: #fff;
						border: 1px solid #1f1f1f;
						border-radius: 10px;
						font-size: 16px;
						height: 50px;
						line-height: 1.2!important;
						margin-bottom: 30px !important;
						padding: 8px 14px;
						width: 100%;
						box-shadow: none;
						@media #{$xl}{
							height: 60px;	
							border-radius: 20px;
						}
					}
					&[type="radio"] {
						width: 24px;
						height: 24px;
						background-color: $white;
						border-radius: 5px;
						margin-right: 30px;
						display: inline-block !important;
						border: 1px solid $black;
						&:checked{
							background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0501 13L0.350098 7.30001L1.7751 5.87501L6.0501 10.15L15.2251 0.975006L16.6501 2.40001L6.0501 13Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
							background-position: center;
							background-repeat: no-repeat;
						}
					}
				}
				.mepr-radios-field{
					display: flex !important;
					flex-wrap: wrap !important;
				}
				.mepr-radios-field-row{
					display: flex !important;
				}
				.mp-form-submit{
					text-align: center;
					input[type="submit"]{
						background-color: #0961ad;
						border: 0;
						border-radius: 50px;
						box-shadow: 0 0 25px 0 rgba(32,32,32,.25);
						color: #fff;
						cursor: pointer;
						display: inline-block;
						font-size: 14px;
						font-weight: 600;
						letter-spacing: 1px;
						line-height: 1.1!important;
						margin-top: 20px;
						padding: 18px 45px;
						text-align: center;
						text-transform: uppercase;
						transition: .5s;
						width: 100%;
						@media #{$sm}{
							width: auto;
						}
					}
				}
			}
		}
		+ .support-us-section{
			margin-top: 0;
		}
	}
	.form-content-wrapper{
		#mepro-login-hero{
			 > .primary-container{
				padding: 0;
			 }
			.form-content-wrapper{
				padding: 0;
				margin: 0;
				box-shadow: none;
			}
		}
	}
	#mepro-login-hero,
	.mepr-account-form{
		max-width: 100%;
		.form-heading{
			font-size: 31px;
			font-weight: 600;
			color: $offBlack;
			margin-bottom: 40px;
		}
		.mepro-boxed{
			max-width: 100%;
			padding: 0;
			margin: 0;
			border: 0;
		}
		.form-inner-header{
			font-size: 28px;
			font-weight: 600;
			margin-bottom: 40px;
			@media #{$xl}{
				font-size: 34px;	
			}
		}
		.mepro-login-contents{
			padding: 0;
		}
		#mepr_loginform{
			h1{
				display: none;
			}
		}
		.mp-form-row{
			&.mepr_remember_me{
				label{
					margin-bottom: 0;
				}
			}
			label{
				position: relative;
				margin: 0;
				overflow: visible;
				clip: unset;
				height: auto;
				display: block !important;
				font-size: 16px;
				font-weight: 500;
				line-height: 1.2;
				margin-bottom: 20px;
				padding: 0;
				@media #{$xl}{
					font-size: 20px;
				}
			}
			
			input{
				background-color: #fff;
				border: 1px solid #1f1f1f;
				border-radius: 10px;
				box-shadow: none;
				font-size: 16px;
				height: 50px;
				line-height: 1.2!important;
				margin-bottom: 30px!important;
				padding: 8px 14px;
				width: 100%;
				@media #{$xl}{
					height: 60px;
					border-radius: 20px;
				}
			}
			&.mepr_remember_me{
				input[type="checkbox"]{
					margin-bottom: 0 !important;
					padding: 0;
					width: 24px;
					height: 24px;
					background-color: $white;
					border-radius: 5px;
					margin-right: 10px;
					&:checked{
						background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0501 13L0.350098 7.30001L1.7751 5.87501L6.0501 10.15L15.2251 0.975006L16.6501 2.40001L6.0501 13Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
						background-position: center;
						background-repeat: no-repeat;
					}
				}
			}
		}
		.login-button-wrapper{
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: center;
			gap: 24px;
			@media (max-width: 640px){
				flex-direction: column;
			}
			.register-btn{
				background-color: $gray;
				@media (max-width: 640px){
					order: 2;
					width: 100%;
				}
			}
		}
		.submit{
			text-align: center;
			@media (max-width: 640px){
				width: 100%;
			}
			input[type="submit"]{
				background-color: #0961ad;
				border: 0;
				border-radius: 50px;
				box-shadow: 0 0 25px 0 rgba(32,32,32,.25);
				color: #fff;
				cursor: pointer;
				display: inline-block;
				font-size: 14px;
				font-weight: 600;
				letter-spacing: 1px;
				line-height: 1.1!important;
				margin-top: 0px;
				padding: 18px 45px;
				text-align: center;
				text-transform: uppercase;
				transition: .5s;
				width: 100%;
				@media (max-width: 640px){
					width: 100% !important;
				}
				@media #{$sm}{
					width: auto;
				}
			}
		}
		.mepr-login-actions{
			text-align: center;
			font-size: 20px;
			a{
				color: $green-80;
				font-weight: 500;				
			}
		}
		.form-content-wrapper{
			background-color: $white;
			border-radius: 35px;
			box-shadow: 0 0 25px rgba($black , 0.15);
			padding: 48px 32px;
			max-width: 930px;
			margin: 0 auto;
			margin-bottom: 100px;
			position: relative;
			@media #{$md}{
				padding: 60px;
			}
			@media #{$lg}{
				padding: 88px;
			}
		}
	}
	.mepr-account-container{
		&.user-details-section{
			display: flex;
			flex-direction: column;
			gap: 0;
			min-height: auto;
			@media (min-width: 768px) and (max-width: 1199px){
				padding-top: 40px;
			}

		}
		// .primary-container{
		// 	max-width: 1100px;
		// }
		#mepr-account-nav{
			background-color: transparent !important;
			// max-width : calc(100% - 48px);
			@media (max-width:767px){
				// background-color: #cedfef !important;
				box-shadow: none;
				margin-bottom: 0;
				padding-top: 0;
				position: relative;
			}
			> span{
				@media (max-width: 767px){
					display: block;
				}
			}
		}
		.btn-pills{
			outline: none;
			padding: 10px 20px;
			@media (max-width: 767px){
				text-transform: capitalize;
				font-weight: 400;
				font-size: 16px;
				padding: 10px 20px;
				line-height: 1.4;
			}			
			&:visited{
				color: $offBlack;
			}
			&:hover{
				color: $offBlack;
			}
		}
		.mepr-active-nav-tab{
			.btn-pills{
				background-color: $green-10;
				color: $green-80;
				@media (max-width: 767px){
					text-align: left;
					padding: 10px 20px;
					background-color: transparent;
					color: $blue;
					font-weight: 400;
					position: relative;
					text-transform: capitalize;
					&:after{
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: -32px;
						content: '';
						width: 16px;
						height: 16px;
						background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_3109_2972' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'%3E%3Crect width='16' height='16' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_3109_2972)'%3E%3Cpath d='M6.36674 12L2.56674 8.2L3.51674 7.25L6.36674 10.1L12.4834 3.98334L13.4334 4.93334L6.36674 12Z' fill='%230961AD'/%3E%3C/g%3E%3C/svg%3E%0A");
					}					
				}
			}
		}		
		#mepr-account-content{
			padding: 0;
			overflow: visible;
			> .primary-container{
				position: relative;
				padding-top: 50px;
				@media (max-width: 767px){
					padding-top: 100px;
				}
			}
			.mepr---content-wrapper{
				background-color: #fff;
				border: 0;
				border-radius: 35px;
				box-shadow: 0 0 25px rgba(32,32,32,.15);
				margin-bottom: 100px;
				padding: 40px 32px;
				position: relative;
				width: 100%;
			}
			.mepr-pro-account-table {
				border-collapse: separate;
				border-spacing: 0px 20px;
				thead {
					th{
						font-size: 16px;
						line-height: 22px;
						color: $black;
						font-weight: 500;
						border-bottom-color: $gray-90;
					}
				}
				tbody {
					td{
						border-bottom: 1px solid $gray;
						padding-bottom: 40px;
						@media (max-width : 767px){
							padding-bottom: 20px;
						}
					}
					td,p,div{
						font-size: 16px;
						line-height: 22px;
						color: $gray-80;
						font-weight: 500;
					}
				}
			}
		}
		#mepr-profile-details{
			width: 100%;
			background-color: $white;
			border-radius: 35px;
			box-shadow: 0 0 25px rgba($black , 0.15);
			padding: 40px 32px;
			margin-bottom: 100px;
			position: relative;
			border: 0;
			@media #{$md}{
				padding: 60px;
			}
			@media #{$lg}{
				padding: 88px;
			}
		}
		.mepr-form {
			&.mepr-account-form,
			&.mepr-newpassword-form{		
				margin-bottom: 30px;	
				.mp-address-group{
					box-shadow: none;
					.mp-form-row{
						input{
							background-color: #fff;
							border: 1px solid #1f1f1f;
							border-radius: 10px;
							box-shadow: none;
							font-size: 16px;
							height: 50px;
							line-height: 1.2!important;
							margin-bottom: 30px!important;
							padding: 8px 14px;
							width: 100%;
							@media #{$xl}{
								border-radius: 20px;
								height: 60px;
							}
						}
						select ,
						.mepr-select-field{
							background-color: #fff;
							border: 1px solid #1f1f1f;
							border-radius: 10px;
							font-size: 16px;
							height: 50px;
							line-height: 1.2!important;
							margin-bottom: 30px !important;
							padding: 8px 14px;
							width: 100%;
							box-shadow: none;
							@media #{$xl}{
								height: 60px;	
								border-radius: 20px;
							}
						}
					}
				}
				.mepr_custom_field{		
					margin-bottom: 0;
					select ,
					.mepr-select-field{
						background-color: #fff;
						border: 1px solid #1f1f1f;
						border-radius: 10px;
						font-size: 16px;
						height: 50px;
						line-height: 1.2!important;
						margin-bottom: 30px !important;
						padding: 8px 14px;
						width: 100%;
						box-shadow: none;
						@media #{$xl}{
							height: 60px;	
							border-radius: 20px;
						}
					}					
					&.mepr_mepr_mobile_number{
						.iti__flag-container{
							bottom: 30px;
							.iti__selected-flag{
								border-top-left-radius: 10px;
								border-bottom-left-radius: 10px;
								@media #{$xl}{
									border-top-left-radius: 20px;
									border-bottom-left-radius: 20px;
								}
							}
						}
						input{
							padding-left: 60px;
						}
					}
				}
				.mp-form-row{
					.mp-form-row-group{
						column-gap: 30px;
						@media (max-width: 767px){
							flex-direction: column;
						}
					}
					.mp-address-group{
						
					}
					label{
						position: relative;
						margin: 0;
						overflow: visible;
						clip: unset;
						height: auto;
						display: block !important;
						font-size: 16px;
						font-weight: 500;
						line-height: 1.2;
						margin-bottom: 20px;
						padding: 0;
						@media #{$xl}{
							font-size: 20px;
						}
					}				
					input{
						background-color: #fff;
						border: 1px solid #1f1f1f;
						border-radius: 10px;
						box-shadow: none;
						font-size: 16px;
						height: 50px;
						line-height: 1.2!important;
						margin-bottom: 30px!important;
						padding: 8px 14px;
						width: 100%;
						@media #{$xl}{
							height: 60px;
							border-radius: 20px;
						}
					}
				}
				.btn-wrapper{
					text-align: center;
				}
				.btn{
					display: inline-block;
					padding: 18px 45px;
					font-size: 14px;
					line-height: 1;
					border-radius: 50px;
					color: $offBlack;
					font-weight: 600;
					text-transform: uppercase;
					letter-spacing: 1px;
					text-align: center;
					&.primary-btn{
						box-shadow: 0 0 25px 0  rgba($black , 0.25);
						background-color: $yellow;
						transition: 0.5s;
						&:hover{
							background-color: $yellow-80;
							box-shadow: 0 0 25px 0  rgba($black , 0.25);
						}
						&.blue-btn{
							background-color: $blue;
							color: $white;
							&:hover{
								background-color: $blue-80;
							}
						}
					}
					
				}
				.forgot-cancel{
					display: block;
					text-align: center;
					font-size: 14px;
					font-weight: 500;
					margin-top: 15px;
				}
			}
		}
		.mepr-profile-wrapper__footer{
			min-width: 700px;
			border: 0;
			width: 100%;
			position: absolute;
			right: 0;
			top: -90px;
			justify-content: center;
						
			@media (min-width: 1299px){
				// padding-left: 504px;
			}
			.btn-pills{
				@media (max-width: 767px){
					text-transform: uppercase;
					margin: 0;
					font-size: 14px;
					font-weight: 600;
					color: $gray-90;
				}
			}
			&:before{
				position: absolute;
				content: '';
				left: 24px;
				right: 24px;
				height: 1px;
				background-color: rgba(0, 0, 0, 0.2);
				top: 0;
				@media (min-width: 1299px){
					// left: 504px;
				}
			}			
			@media (max-width: 1298px){
				min-width: auto;
				position: absolute;
				right: 50%;
				top: -60px;
				transform: translateX(50%);
				justify-content: center;
				padding-top: 0.6rem;    
				white-space: nowrap;
				flex-wrap: nowrap;
			}
			@media (max-width: 1300px){
				width: 100%;
				right: 0;
				left: 0;
				transform: unset;
				flex-wrap: wrap;
			}
		}
	}
}


.bbp-forums{
	ul {
		display: flex;
		flex-direction: row;
	}
	li{
		width: 25%;
    	list-style: none;
	}
}
.single-topic{
	*{
		font-family: $font;
	}
	.bbpress-wrapper{
		padding: 50px 0;
		.bbp-breadcrumb,
		#subscription-toggle,
		#favorite-toggle,
		.bbp-template-notice,
		.bbp-pagination{
			display: none;
		}
		&#bbpress-forums{
			.comments-listing-wrapper{
				background-color: $white;
				ul.bbp-replies{
					border: 0;
				}
				.bbp-reply-header{
					border-top: 0;
				}
				.bbp-reply-author{
					flex: 0 0 50px;
					max-width: 50px;
					.bbp-author-avatar{
						background-color: #c7d2d2;
						border-radius: 50%;
						overflow: hidden;
						padding-top: 100%;
						position: relative;
						display: block;
						img{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							object-fit: cover;
							margin: 0;
						}
					}
					.bbp-author-name,
					.bbp-author-role,
					.bbp-reply-ip{
						display: none;
					}
				}
				.bbp-reply-content{
					flex: 0 0 calc(100% - 50px);
					max-width: calc(100% - 50px);
					padding: 0;
					padding-left: 48px;
					margin: 0;
				}
				.list-items{
					.reply {
						padding: 0;
						background-color: transparent;
					}
					.user-details{
						margin-top: 24px;
						.bbp-author-avatar,
						.bbp-author-role{
							display: none;
						}
					}
				}
			}
		}		
	}
	
}
.thankyou-page-wrapper{
	.mepr-checkout-container{ 
		min-height: auto;
		.invoice-wrapper {
			&.thankyou{
				padding: 60px 20px !important;
				p{
					color: $offBlack;
				}
				h2{
					margin-bottom: 50px;
				}
				.mepr-order-no{
					p{
						font-size: 18px;
						font-weight: 600;
					}
				}
			}
		}
	}	
}

.pattern-gredient {
	position: absolute;
	width: 100%;
	background-image: $lightGreenGradient;
	border-bottom-left-radius: 35px;
	border-bottom-right-radius: 35px;
	z-index: 0;
	@media #{$xl}{
		border-bottom-left-radius: 55px;
		border-bottom-right-radius: 55px;
	}	
	~ .has-gradient-bg{
		position: relative;
	}
}
.social-share-modal {
	.modal-content{
		@media (max-width: 767px){
			padding: 40px;
			padding-bottom: 70px;
		}
		.case-information .case-title{
			max-width: 100%;
		}
	}
	.socials-icons{
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		position: relative;
		.icon-share{
			width: 40px;
			height: 40px;
			border: 1px solid $gray;
			background-color: transparent;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 10px;
			i{
				font-size: 20px;
				line-height: 20px;
				color: $black;
			}
			&.copy-link{
				width: auto;
				svg{
					fill : $black;
				}
			}
			&:hover{
				border: 1px solid $green;
				background-color: rgba($green, 0.2);
				color: $green;
				i{
					color: $green;
				}
				svg{
					fill: $green;
				}
			}
		}
		span.link-notification {
			position: absolute;
			top: 150%;
			padding: 5px;
			width: 190px;
			text-align: center;
			left: 0;
			border: 1px solid $green;
			background-color: rgba($green, 0.2);
			color: $green;
			display: none;
		}
		
	}
}
.medium {
	font-weight: 500;
}
.semi {
	font-weight: 600;
}
.bold {
	font-weight: 700;
}

.single-memberpressproduct{
    .inner-banner {
        .content-wrapper {
            .fullwidth-part {
                .sub-content{
                    max-width: 660px;
                }
            }
        }
    }
}
body.upload-profile-image{
    .inner-banner{
        &.user-page-banner {
            .content-wrapper {
                .right-part {
                    .image-wrapper{
                        &:hover{
                            .upload-image-wrapper{
                                opacity: 1;
                                visibility: visible;
                            }
                            .wpua-edit{
                                &:after{
                                    opacity: 1;
                                    visibility: visible;
                                    transition: 0.5s;
                                }
                            }
                            
                            .wpua-edit-container {
                                #wpua-add-button-existing{
                                    opacity: 1;
                                    visibility: visible;
                                }                            
                            }
                            .submit{
                                bottom: 20px;
                            }
    
                        }
                    }
                }
            }
        }
    }
}

.inner-banner{
    &.user-page-banner{
        margin-bottom: 100px;
        .content-wrapper{
            .right-part{
                max-width: 400px;
                flex: 0 0 400px;
                @media #{$xlg}{
                    margin-bottom: -0px;
                }
                @media #{$md}{
                    margin-bottom: -0px;
                }
               
                @media (max-width: 1024px){
                    max-width: 300px;
                    flex: 0 0 300px;
                }
                @media (max-width: 575px){
                    max-width: 100%;
                    flex: 0 0 100%;
                }
                .image-wrapper{
                    position: relative;
                    z-index: 100;
                    // cursor: pointer;
                    @media #{$md}{
                        margin-bottom: -50px;
                    }
                    @media #{$xlg}{
                        margin-bottom: -100px;
                    }
                    
                    .success{
                        display: none;
                    }
                    .wpua-edit{
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        &:after{
                            position: absolute;
                            content: "";
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(255,255,255,0.5);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 0;
                            visibility: hidden;
                            transition: 0.5s;
                        }
                    }
                    .wpua-edit-container {
                        h3,
                        #wpua-thumbnail-existing,
                        #wpua-remove-button-existing{
                            display: none;
                        }
                        #wpua-add-button-existing{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 2;
                            opacity: 0;
                            visibility: hidden;
                            transition: 0.5s;
                            button{
                                background-color: transparent;
                                border: 0;
                                background-image: url("data:image/svg+xml,%3Csvg width='52' height='47' viewBox='0 0 52 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00065 47C3.71732 47 2.61871 46.543 1.70482 45.6292C0.790929 44.7153 0.333984 43.6166 0.333984 42.3333V14.3333C0.333984 13.05 0.790929 11.9514 1.70482 11.0375C2.61871 10.1236 3.71732 9.66665 5.00065 9.66665H12.3507L16.6673 4.99998H30.6673V9.66665H18.709L14.4507 14.3333H5.00065V42.3333H42.334V21.3333H47.0006V42.3333C47.0006 43.6166 46.5437 44.7153 45.6298 45.6292C44.7159 46.543 43.6173 47 42.334 47H5.00065ZM42.334 14.3333V9.66665H37.6673V4.99998H42.334V0.333313H47.0006V4.99998H51.6673V9.66665H47.0006V14.3333H42.334ZM23.6673 38.8333C26.584 38.8333 29.0632 37.8125 31.1048 35.7708C33.1465 33.7291 34.1673 31.25 34.1673 28.3333C34.1673 25.4166 33.1465 22.9375 31.1048 20.8958C29.0632 18.8541 26.584 17.8333 23.6673 17.8333C20.7507 17.8333 18.2715 18.8541 16.2298 20.8958C14.1882 22.9375 13.1673 25.4166 13.1673 28.3333C13.1673 31.25 14.1882 33.7291 16.2298 35.7708C18.2715 37.8125 20.7507 38.8333 23.6673 38.8333ZM23.6673 34.1666C22.034 34.1666 20.6534 33.6028 19.5257 32.475C18.3979 31.3472 17.834 29.9666 17.834 28.3333C17.834 26.7 18.3979 25.3194 19.5257 24.1916C20.6534 23.0639 22.034 22.5 23.6673 22.5C25.3006 22.5 26.6812 23.0639 27.809 24.1916C28.9368 25.3194 29.5007 26.7 29.5007 28.3333C29.5007 29.9666 28.9368 31.3472 27.809 32.475C26.6812 33.6028 25.3006 34.1666 23.6673 34.1666Z' fill='%231F1F1F'/%3E%3C/svg%3E%0A");
                                padding-top: 50px;
                                background-repeat: no-repeat;
                                background-position: top center;
                                background-size: 40px;
                                color: $offBlack;
                                font-weight: 700;
                                text-transform: uppercase;
                            }
                        }
                    }
                    .submit{
                        margin: 0;
                        position: absolute;
                        bottom: -50px;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 999;
                        transition: 0.5s;
                        display: none;
                        .button{
                            background-color: $blue;
                            color: $white;
                            border: 0;
                            padding: 6px 24px;
                            border-radius: 50px;
                            font-size: 14px;
                        }
                    }
                    #wpua-preview-existing{
                        margin: 0;
                        .description{
                            display: none;
                        }
                        img{
                            max-height: 100%;
                            border: 0;
                        }
                    }
                    .upload-image-wrapper{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(255,255,255,0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: 0;
                        visibility: hidden;
                        transition: 0.5s;
                    }
                }
            }
            .left-part{
                padding-right: 0;                
                min-height: auto;
                align-self: flex-start;
                order : -1;
                @media #{$md}{
                    padding-left: 80px;
                    max-width: calc(100% - 400px);
                    flex: 0 0 calc(100% - 400px);
                    order : 0;
                }
                @media (max-width: 1024px){
                    padding-left: 32px;
                    max-width: calc(100% - 300px);
                    flex: 0 0 calc(100% - 300px);
                }
                @media (max-width: 768px){
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-left: 0px;
                }
                
                
                .wrapper{
                    max-width: 100%;                    
                    @media #{$md}{                        
                        padding-bottom: 25px;
                    }
                    @media #{$xlg}{
                        min-height: 295px;
                    }

                    .sub-content{
                        max-width: 560px;
                    }
                }
                .sub-content{
                    letter-spacing: 0.5px;
                    max-width: 100%;
                }
            }
        }
        + .user-details-section{
            @media (min-width: 1299px){
                // margin-top: -42px;
            }
            
        }
    }
}
.user-details-section{ 
    .loadmore-btn-wrapper{
        display: flex;
        justify-content: center;
        #loadmore{
            cursor: pointer;
            display: none;
        }
    }
}
.profile-listing-wrapper{
    position: relative;
    margin-bottom: 80px;
    z-index: 99;
    margin-top: 20px;
    @media (min-width:1299px){
        margin-bottom: 120px;
        // margin-top: 0px;
    }
    .mobile-drop-down-wrapper{
        @media (max-width: 767px){
            border: 1px solid #1f1f1f;
            border-radius: 7px;
            box-shadow: 0 0 10px rgba(0,0,0,0.15);
            > .mobile-drop-down{
                border: 0;    
            }
            .campaign-listing,
            .user-profile-nav{                   
                position: relative;
                margin-bottom: 0;
                background-color: transparent;
                box-shadow: none;
                padding-top: 0;                 
            }
        }
    }
    .primary-container{
        max-width: 1300px;
    }       
    span.mobile-drop-down{
        display: block;
        border: 1px solid #1f1f1f;
        height: 50px;                
        font-size: 16px;
        line-height: 1.2;
        line-height: 48px;
        padding: 0 20px;
        border-radius: 7px;
        position: relative;
        z-index: 2;
        background:  $gray-10;            
        @media #{$md}{
            display: none;
        }
        &::before{
            position: absolute;
            content: '';
            height: 24px;
            width: 24px;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 8.17315L0 2.0507L1.4 0.622131L6 5.31601L10.6 0.622131L12 2.0507L6 8.17315Z' fill='%231F1F1F'/%3E%3C/svg%3E%0A");                    
            background-position: center;
            background-repeat: no-repeat;
            background-size: 12px;                                        
        }
    }
    .user-profile-nav{      
        width : 100%;
        list-style: none;
        @media #{$md}{
            display: flex !important;
            // justify-content: flex-end;
            flex-wrap: wrap;
            list-style: none;
            gap : 10px;                
        }
        // @media (max-width: 1299px){
            justify-content: center;
        // }
        @media (max-width: 767px){
            background-color:  $blue-10;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 20px 0;
            top: calc(100% - 5px);
            box-shadow: inset 0 0 4px rgba(0,0,0,0.15);
            position: absolute;
            display: none;
        }
        // @media (min-width: 1299px){
        //     padding-left: calc(420px + 60px);
        //     justify-content: flex-start;
        // }
    }    
    .child-item{
        &.active{
            .btn-pills{
                background-color: $green-10;
                color: $green-80;
            }
        }
    }    
}
.case-listing-wrapper{
    margin-bottom: 200px;
    position: relative;
    padding: 0px;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    margin-top: 60px;
    @media #{$md}{
        padding: 72px;
        margin-top: 100px;
        border-radius: 35px;
        box-shadow: 0 0 25px rgba($black, 0.15);
        background-color: $white;    
    }
    .case-item-wrapper{
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        // flex-direction: column-reverse;
        // gap: 64px;
        .no-result-li{
            color: #00514b;
            font-size: 20px;
            margin-bottom: 10px;
            text-align: center;
        }
    }
    .case-item{
        display: none;
        position: relative;
        padding: 0;
        background-color: rgba($low-light-10, 0);
        border-radius: 15px;
        transition: 0.5s;            
        cursor: pointer;
        margin-bottom: 64px;
        @media #{$md}{
            padding: 16px 32px;
        }
        &::after{
            position: absolute;
            content: "";
            right: 0;
            left: 0;
            width: 100%;
            height: 1px;
            display: block;
            background: $gray-40;
            bottom: -32px;
        }
        
        &:last-child,
        &:nth-last-child(2){
            margin-bottom: 0;
            &::after{
                display: none;
            }
        }
        &:hover{
            background-color: rgba($low-light-10, 0.5);
            @media (max-width : 640px){
                background-color: rgba($low-light-10, 0);    
            }
        }
        .wrapper{
            position: relative;
            display: block;
            @media (max-width : 640px){
                display: flex;
                flex-direction: column;
            }
        }
    }
    .case-title{
        color: $green-80;
        font-size: 20px;
        margin-bottom: 10px;
        max-width: calc(100% - 120px);
        @media #{$md}{
            font-size: 25px;
        }
        @media (max-width : 640px){
            max-width: 100%;
        }
        span{
            display: inline-block;
            margin-left: 24px;
        }
    }
    .case-time{
        font-size: 12px;
        text-transform: uppercase;
        color: $low-light-40;
    }
    .case-status{
        position: absolute;
        top: 0;
        right: 0;
        @media (max-width : 640px){
            position: static;
            margin-left: auto;
        }
    }
    .latest-comment {
        margin-top: 16px;
        .title {
            color: $gray-80;
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 8px;
            font-weight: 400;
            display: none;
            @media #{$md}{
                display: block;
            }
        }
        .comment-content{
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0;
            @media #{$md}{
                font-size: 18px;    
            }
            p{
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0;
                margin: 0;
                @media #{$md}{
                    font-size: 18px;    
                }
            }
            
        }
    }
    .pagination-wrapper{
        position: absolute;
        width: 100%;
        top: calc(100% + 80px);
        left: 0;
        right: 0;
    }
}
.user-details{
    .child-item{
        &.active{
            display: block;
        }
        display: none;
    }
    .content-wrapper{
        max-width: 930px;
        margin: 0 auto;
    }
    
}
.case-details-wrapper{
    margin-bottom: 80px;
}
.topic-create-wrapper{
    padding: 180px 0;
    background-image: $lightGreenGradient;
    // .bbp-breadcrumb{
    //     display: none;
    // }
    // .bbp-topic-form{
    //     .bbp-form{
    //         border: 0;
    //         padding: 0;
    //         > legend,
    //         .bbp-template-notice{
    //             display: none;
    //         }
    //     }
    // }
    // .form-wrapper{
    //     .form-control{
    //         margin-bottom: 20px;
    //         @media #{$md}{
    //             margin-bottom: 30px;
    //         }
    //         input{
    //             background-color: #fff;
    //             border: 1px solid #1f1f1f;
    //             border-radius: 10px;
    //             box-shadow: none;
    //             font-size: 16px;
    //             height: 50px;
    //             line-height: 1.2!important;
    //             padding: 8px 14px;
    //             width: 100%;
    //             outline: none;
    //             @media #{$lg}{
    //                 height: 60px;
    //             }                    
    //         }
    //         select{
    //             background-color: #fff;
    //             border: 1px solid #1f1f1f;
    //             border-radius: 10px;
    //             box-shadow: none;
    //             font-size: 16px;
    //             height: 50px;
    //             line-height: 1.2!important;
    //             padding: 8px 14px;
    //             width: 100%;
    //             background-image: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.10811 10L0 1.89189L1.89189 0L8.10811 6.21622L14.3243 0L16.2162 1.89189L8.10811 10Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
    //             background-size: 16px;
    //             background-position: 97%;
    //             background-repeat: no-repeat;
    //             outline: none;
    //             @media #{$lg}{
    //                 height: 60px;
    //             }
    //         }              
    //         label{
    //             display: inline-block!important;
    //             font-size: 16px;
    //             font-weight: 500;
    //             line-height: 1.2;
    //             margin-bottom: 15px;
    //             padding: 0;
    //             @media #{$md}{
    //                 font-size: 20px;
    //                 margin-bottom: 20px;
    //             }
    //         }
    //         &.form-type-checkbox{
    //             display: flex;    
    //             margin-top: 30px;                
    //             input{
    //                 &[type="checkbox"]{
    //                     width: 20px;
    //                     height: 20px;
    //                     background-color: $white;
    //                     border-radius: 5px;
    //                     margin-right: 16px;
    //                     padding: 0;
    //                     @media #{$md}{
    //                         width: 24px;
    //                         height: 24px;
    //                         margin-right: 30px;    
    //                     }
    //                     &:checked{
    //                         background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0501 13L0.350098 7.30001L1.7751 5.87501L6.0501 10.15L15.2251 0.975006L16.6501 2.40001L6.0501 13Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
    //                         background-position: center;
    //                         background-repeat: no-repeat;
    //                     }
    //                     + label{
    //                         max-width: calc(100% - 50px);
    //                         font-size: 16px;
    //                         line-height: 1.2;
    //                         font-weight: 500;
    //                         @media #{$md}{
    //                             font-size: 20px;
    //                             max-width: calc(100% - 60px);
    //                         }
    //                     }
    //                 }
    //             }                    
    //         }
    //         &.form-type-radio{
    //             display: flex;
    //             flex-wrap: wrap;
    //             input{
    //                 &[type="radio"]{
    //                     width: 24px;
    //                     height: 24px;
    //                     background-color: $white;
    //                     border-radius: 5px;
    //                     margin-right: 0px;
    //                     flex: 0 0 24px;
    //                     padding: 0;
    //                     @media (max-width: 640px){
    //                         flex: 0 0 20px;   
    //                         width: 20px;
    //                         height: 20px;
    //                     }
    //                     &:checked{
    //                         background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0501 13L0.350098 7.30001L1.7751 5.87501L6.0501 10.15L15.2251 0.975006L16.6501 2.40001L6.0501 13Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
    //                         background-position: center;
    //                         background-repeat: no-repeat;
    //                     }
    //                 }
    //             }    
    //             .radio-wrapper{
    //                 width: 100%;
    //                 display: flex;
    //                 column-gap: 50px;
    //                 row-gap: 15px;
    //                 flex-wrap: wrap;
    //                 @media (max-width: 640px){
    //                     flex-direction: column;
    //                 }
    //                 .radio-item{
    //                     display: flex;
    //                     gap: 16px;
    //                 }
    //             }                
    //         }
    //         &.action-btn-wrapper{
    //             text-align: center;
    //         }
    //         button.blue-btn{
    //             border-radius: 50px;
    //             display: inline-block;
    //             font-size: 14px;
    //             font-weight: 600;
    //             letter-spacing: 1px;
    //             line-height: 1;
    //             padding: 18px 45px;
    //             text-align: center;
    //             text-transform: uppercase;
    //             border-color: #0961ad;
    //             background-color: #0961ad;
    //             color: #fff;
    //             &:hover{
    //                 border-color: #053157;
    //                 background-color: #053157;
    //                 color: #fff;                        
    //             }
    //         }
    //     }          
    // }
}

.resources-listing-wrapper{
	padding: 80px 0;
	background-image: $lightGreenGradient;
    .primary-container{
        max-width: 1130px;
    }
}
.benefit-resources-wrapper{
    margin-bottom: 80px;
}
.event-listing{
    padding-bottom: 180px;
    position: relative;
    &::before{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        background-image: $lightGreenGradient;
        height: 350px;
        border-top-right-radius: 35px;
        border-top-left-radius: 35px;
    }
    .primary-container{
        max-width: 1130px;
    }
}
.benefit-resources-wrapper{
    .campaigns-grid-wrapper{
        row-gap: 40px;
        @media (max-width: 767px){
            padding: 0;
            display: flex;
            flex-wrap: wrap;
        }
        .grid-item{
            @media (max-width: 767px){
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 10px;
            }    
            @media (max-width: 575px){
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 10px;
            }    
        }
        .content-wrapper{
            .post-category{ 
                display: flex;
                gap: 15px;
                margin-bottom: 25px;
                .category-item{
                    color: #00544f;
                    font-size: 12px;
                    font-weight: 400;
                    position: relative;
                }
            }
            .short-description{
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: unset;
            }
        }
    }
}

.recent-resources-wrapper{
	position: relative;
	&:after{
		position: absolute;
		content: "";
		width: 100%;
		z-index: 0;
		pointer-events: none;
		height: 200px;
		// background-image: $lightGreenGradient;
		border-top-right-radius: 35px;
		border-top-left-radius: 35px;
		bottom: 0;
        background-color: #cbdcdb;
	}
    .primary-container{
        max-width: 1130px;
    }
	.post-listing-wrapper{
		margin: 0 -16px;
	}
}
.new-rquest-form-section{
    padding: 200px 0;
    border-top-right-radius: 55px;
    border-top-left-radius: 55px;
    background-image: $lightGreenGradient;
    .form-content-wrapper{
        background-color: #fff;
        border-radius: 35px;
        box-shadow: 0 0 25px rgba(32,32,32,.15);
        padding: 48px 32px;
        position: relative;
        @media #{$md}{
            padding: 60px;
        }
        @media #{$lg}{
            padding: 88px;
        }
    }
}

.case-fileters{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 80px;
    margin-bottom: 80px;
    &.start{
        justify-content: flex-start;
    }
    .filter-item{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        span,label{
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 600;
            color : $gray-60;
            display: flex;
            align-items: center;
            gap: 16px;
            justify-content: flex-end;
            svg{
                height: 24px;
                width: 24px;
            }
        }
        &.active{
            .filter-menu{
                opacity: 1;
                visibility: visible;
                transition: 0.5s;
            }
        }
        .filter-menu{
            background-color: #fff;
            list-style: none;
            position: absolute;
            top: 100%;
            padding: 0;
            margin: 0;
            min-width: 250px;
            right: 0;
            padding: 20px;
            border-radius: 10px;
            border-top-right-radius: 0;
            box-shadow: 0 5px 9px rgba(0,0,0,0.05);
            opacity: 0;
            visibility: hidden;
            z-index: 9;
            transition: 0.5s;
            
            li{
                padding: 10px 0;
                font-size: 16px;
            }
        }
    }
}

/* Optional styles for the modal */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 9999;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    @media (max-width: 767px){
        padding: 20px 10px;
    }
}

.modal-content {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    background-color: $white;
    padding: 72px;
    max-width: 930px;
    width: 100%;
    box-shadow: 0 0 25px rgba($black, 0.15);
    border-radius: 35px;
    margin: auto;
    @media (max-width: 767px){
        padding: 20px;
    }
    .modal-header{
        margin-bottom: 30px;
    }
    .modal-actions{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        
    }
    .close {
        color: $offBlack;    
        font-size: 28px;
        svg{
            fill: $offBlack;
            height: 20px;
            width: 20px;
        }
        &:hover,
        &:focus {
            color: $green;
            text-decoration: none;
            cursor: pointer;
            svg{
                fill: $green;
            }
        }        
    }
    .more-actions{
        position: relative;
        .action-btn{
            position: relative;
            z-index: 2;
            display: inline-flex;
            cursor: pointer;
            svg{
                fill: $offBlack;
            }
        }
        .action-menu-options{
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 0;
            white-space: nowrap;
            background-color: #fff;
            box-shadow: 0 0 15px rgba(0,0,0,0.15);
            padding-right: 24px !important;
            border-radius: 6px;
            transition: 0.5s;
            right: -150px;
            min-width: 220px;
            opacity: 0;
            visibility: hidden;
            &.active{
                opacity: 1;
                visibility: visible;
                right: 0;
            }
            .action-item{
                width: 100%;
                padding: 14px 20px;
                display: inline-block;
                line-height: 1;
            }
        }
    }
    .case-information{
        margin-bottom: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        .case-title{
            color: #00514b;
            font-size: 25px;
            margin-bottom: 10px;
            max-width: calc(100% - 120px);
            @media #{$md}{
                max-width: calc(100% - 120px);    
            }
            span{
                display: inline-block;
                margin-left: 24px;
            }
        }
        .case-time{
            color: #558d8a;
            font-size: 12px;
            text-transform: uppercase;
        }
        .case-status{
            position: static;
            right: 0;
            order: -1;
            align-self: flex-start;
            margin-bottom: 10px;
            @media #{$md}{
                position: absolute;
                right: 0;
                top: 0;
            }
            
        }
    }
    .add-comment-wrapper{       
        background-color: $gray-20;
        border-radius: 10px; 
        .zendesk-comment-form-wrapper_wrapper{
            .zendesk-comment-form-wrapper{
                display: flex;
                align-items: flex-end;
                gap: 20px;
                .gform-body{
                    flex: 1;
                }
            }
            .gform_heading{
                display: none;
            }
            textarea{
                background-color: $gray-20;
                border-radius: 10px;
                padding: 15px;
                border: 0;
                outline: none;
                resize: none;
            }
            .gform_footer{
                padding: 0;
                margin: 0;
                #gform_submit_button_9{
                    margin: 0;
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='35' height='35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='a' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='35' height='35'%3E%3Cpath fill='%23D9D9D9' d='M0 0h35v35H0z'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Cpath d='M4.375 29.167V5.833L32.083 17.5 4.375 29.167Zm2.917-4.375 17.28-7.292-17.28-7.292v5.104l8.75 2.188-8.75 2.187v5.105Z' fill='%23858C8C'/%3E%3C/g%3E%3C/svg%3E");
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: 25px;
                    border-radius: 50%;
                    cursor: pointer;
                    font-size: 0;
                    height: 35px;
                    margin: 0;
                    width: 35px;
                    border: 0;
                    background-color: transparent;
                    position: relative;
                    bottom: 15px;
                    right: 10px;
                    &:hover{
                        background-color: $gray-40;
                    }
                }
            }
        }    
    }
}
.comments-listing-actions{
    .case-fileters{
        margin-bottom: 16px;
    }
}
.comments-listing-wrapper{
    margin-bottom: 60px;
    .list-items{
        padding: 32px;
        &:not(:last-child){
            border-bottom: 1px solid $gray-40;
        }
        @media(max-width: 767px){
            padding: 30px 0;
        }
        .wrapper{
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        .comment-detail{
            display: flex;
            flex-wrap: wrap;
            .user-image{
                flex : 0 0 50px;
                max-width: 50px;
                .image-wrapper{
                    position: relative;
                    padding-top: 100%;
                    border-radius: 50%;
                    background-color: $gray;
                    overflow: hidden;
                    img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .user-comment{
                flex : 0 0 calc(100% - 50px);
                max-width: calc(100% - 50px);
                padding-left: 48px;
            }
        }
        .user-details{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: column;
            @media #{$md}{
                flex-direction: row;
            }
            .posted-by{
                font-size: 12px;
                color: $low-light;
                text-transform: uppercase;
            }
            .posted-date{
                font-size: 12px;
                color: $low-light;
                text-transform: uppercase;
            }
        }
    }
}

.popup-wrap {
    display: none;
    .case-item-wrapper {
        .case-item {
            display: none;
        }
    }
}
.case-support-wrapper +
.get-involve-section{
    display: none;
}
.featured-event{
    &-resources-wrapper{
        padding: 30px 0 120px;
        @media #{$md}{
            padding: 30px 0 160px;
        }
        .primary-container{
            max-width: 980px;
        }
    }
    &-item-wrapper{
        .content-wrapper{
            margin-bottom: 30px;
            max-width: 100%;
            .post-date{
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                color: $low-light;
                margin-bottom: 10px;
            }
            .h5{
                font-size: 39px;
                line-height: 48px;  
                font-weight: 600;
                margin-bottom: 10px;
            }
            .location-info{
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                color: $gray-70;
                margin-bottom: 10px;
            }
            .short-description{
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: $offBlack;
                
            }
        }
        .grid-item{
            .image-wrapper{ 
                padding-top: 200px;
                position: relative;
                border-radius: 20px;
                box-shadow: 0 5px 20px rgba(0,0,0,.1);
                overflow: hidden;
                img,
                video,
                iframe{
                    bottom: 0;
                    height: 100%;
                    left: 0;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: center;
                    object-position: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                }
            }
        }
    }
}
// Define all style variables here.

// Colours.
$white: #ffffff;
$black: #202020;
$offBlack : #1f1f1f;

$darkGreen: #003632;
$lightGreen: #006b64;
$primary: $lightGreen;

$low-light-10 : #ccdddc;
$low-light-20 : #aac6c4;
$low-light-30 : #80aaa7;
$low-light-40 : #558d8a;
$low-light-50 : #2a716c;
$low-light : #00544f;
$low-light-60 : #004642;
$low-light-70 : #003835;
$low-light-80 : #002a28;
$low-light-90 : #001c1a;
$low-light-100 : #001110;

$yellow-10 : #ffffcc;
$yellow-20 : #FFFFAA;
$yellow-30 : #FEFF80;
$yellow-40 : #feff55;
$yellow-50 : #feff2a;
$yellow : #FEFF00;
$yellow-60 : #d4d500;
$yellow-70 : #a9aa00;
$yellow-80 : #7F8000;
$yellow-90 : #555500;
$yellow-100 : #333300;

$blue-10 : #cedfef;
$blue-20 : #adcae4;
$blue-30 : #84b0d6;
$blue-40 : #5b96c8;
$blue-50 : #327bbb;
$blue : #0961AD;
$blue-60 : #085190;
$blue-70 : #064173;
$blue-80 : #053157;
$blue-90 : #03203a;
$blue-100 : #021323;

$green-10 : #ccecea;
$green-20 : #aae0dc;
$green-30 : #80d0cb;
$green-40 : #55c0b9;
$green-50 : #2AB1A8;
$green : #00A196;
$green-60 : #00867d;
// $green-70 : #006b64;
$green-80 : #00514b;
// $green-90 : #003632;
$green-100 : #00201e;

$gray-10 : #f4f6f6;
$gray-20 : #ecf0f0;
$gray-30 : #E3E9E9;
$gray-40 : #DAE1E1;
$gray-50 : #D0DADA;
$gray    : #c7d2d2;
$gray-60 : #a6afaf;
$gray-70 : #858c8c;
$gray-80 : #646969;
$gray-90 : #424646;
$gray-100 : #282a2a;

$greenGradient : linear-gradient( 90deg, $lightGreen 33.19%,#00514b 55.56%,$darkGreen 84.8% );
$lightGreenGradient : linear-gradient( 0deg ,$low-light-20 , $low-light-10 );
$blueGradient : linear-gradient( 90deg, $blue-60 0%, $blue-70 98%);


// Fonts.
@import url("https://use.typekit.net/uoy5byi.css");
$font: "acumin-pro", sans-serif;

// Media querys.
$sm: "(min-width: 575px)";
$md: "(min-width: 768px)";
$lg: "(min-width: 992px)";
$xlg: "(min-width: 1025px)";
$xl: "(min-width: 1200px)";
$large: "(min-width: 1366px)";
$desktop: "(min-width: 1600px)";
$extra: "(min-width: 1920px)";

// Misc.
$ease: all 0.3s ease-in-out;

.btn{
    display: inline-block;
    padding: 18px 45px;
    font-size: 14px;
    line-height: 1;
    border-radius: 50px;
    color: $offBlack;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    &.primary-btn{
        box-shadow: 0 0 25px 0  rgba($black , 0.25);
        background-color: $yellow;
        transition: 0.5s;
        &:hover{
            background-color: $yellow-80;
            box-shadow: 0 0 25px 0  rgba($black , 0.25);
        }
        &.blue-btn{
            background-color: $blue;
            color: $white;
            &:hover{
                background-color: $blue-80;
            }
        }
    }
    &.icon-btn{
        padding: 0;
        color: $white;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $font !important;
        &:hover{
            color: $yellow; 
            svg{
                fill: $yellow;
            }
        }
        svg{
            height: 20px;
            width: 20px;
            margin-left: 20px;
            fill: $white;
            @media #{$md}{
                height: 25px;
                width: 25px;    
            }
        }
    }
    &.btn-pills{
        padding: 14px 32px;
        color: $gray-80;
        font-weight: 600;
        background-color: transparent;
        border-radius: 5px;
        transition: 0.5s;
        cursor: pointer;
        &:hover{
            color: $offBlack;
        }
        &.active{
            background-color: $green-10;
            color: $green-80;
        }
    }    
}
.btn-chip{
    font-size: 12px;
    text-transform: uppercase;
    padding: 4px 16px;
    border-radius: 50px;
    display: inline-block;
    // background-color: $blue-10;
    // color: $blue-70;
    &.ongoing {
        background-color: $blue-10;
        color: $blue-70;
    }
    &.waiting {
        background-color: $yellow-20;
        color: $yellow-90;
    }
    &.closed {
        background-color: $gray-50;
        color: $gray-90;
    }
}
/* jQuery animation; slide up assets on scroll
* Add the class 'module' followed by either 'fadeUp', 'fadeLeft', 'fadeRight'
* to elements that you wish to be animated on scroll. 
*/

.come-in {
	opacity: 0;

	&.fadeUp {
		transform: translateY(150px);
		opacity: 0;
		animation: fadeUp 1.5s ease forwards;
	}
	&.fadeLeft {
		opacity: 0;
		animation: fadeUp 1.5s ease forwards;
		transform: translateY(150px);

		@media #{$md} {
			transform: translateX(-150px);
			animation: fadeLeft 1.5s ease forwards;
		}
	}
	&.fadeRight {
		opacity: 0;
		animation: fadeUp 1.5s ease forwards;
		transform: translateY(150px);

		@media #{$md} {
			transform: translateX(150px);
			animation: fadeRight 1.5s ease forwards;
		}
	}
}
.already-visible {
	transform: translate(0, 0) !important;
	opacity: 1 !important;
	animation: none !important;
}

@keyframes fadeUp {
	to {
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fadeLeft {
	to {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes fadeRight {
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

// On Load
.on-load {
	transform: translateY(0) !important;
	opacity: 1 !important;
}

.primary-container {
	padding-right: 24px;
	padding-left: 24px;
	width: 100%;
	// max-width: 100%;
	margin: 0 auto;
	max-width: 1300px;

	// @media #{$sm} {
	// 	padding-right: 0px;
	// 	padding-left: 0px;
	// 	max-width: 540px;
	// }
	// @media #{$md} {
	// 	max-width: 720px;
	// }
	// @media #{$lg} {
	// 	max-width: 960px;
	// }
	// @media #{$xl} {
	// 	max-width: 1140px;
	// }
	// @media #{$large} {
	// 	max-width: 1250px;
	// }
	// @media #{$desktop} {
	// 	max-width: 1350px;
	// }
	// @media #{$extra} {
	// 	max-width: 1620px;
	// }
	// &.small{
	// 	max-width: 930px;
	// }
	// &.medium{
	// 	max-width: 1050px;
	// }
	// &.large{
	// 	max-width: 1176px;
	// }
}

.slick-dots{
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: center;
	li{
		button{
			background-color: $gray-50;
			font-size: 0;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 0;
		}                
		&.slick-active{
			button{
				background-color: $green;
			}
		}
	}
}
.hide-desktop{
	@media #{$md}{
		display: none;
	}
}
.about-card-section{
    margin: 120px 0;
    overflow: hidden;
    @media #{$md}{
        margin: 190px 0;
        overflow: auto;
    }
    .primary-container{
        max-width: 1160px;
    }
    .section-heading {
        margin-bottom: 20px;
        @media #{$md}{
            margin-bottom: 55px;
        }
        .h2{
            text-align: center;
            padding: 0 15px;
            @media #{$md}{
                text-align: left;
                padding: 0;
            }
            @media (max-width: 640px){
                br{
                    display: none;
                }
            }
        }
    }
    .card-item-wrapper {
        position: relative;
        &::after{
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            right: -24px;
            width: 40px;
            height: 100%;
            background: linear-gradient(90deg , rgba($gray-10, 0) , rgba($gray-10, 1));
        }
        @media#{$md}{
            display: flex;
            flex-wrap: wrap;
            &::after{
                display: none;
            }
        }
        .slick-list{
            overflow: visible;
        }
    }
    .card-items {
        padding: 0 10px;    
        @media#{$md}{
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0;
            padding-right: 50px;
        }
        @media#{$xl}{
            padding-right: 76px;
        }
        &:nth-child(even){
            @media#{$md}{
                transform: translateY(50%);
                padding-right: 0px;
                padding-left: 50px;
            }
            @media#{$xl}{
                padding-right: 0px;
                padding-left: 76px;
            }
            .wrapper{                
                &::before{
                    @media#{$md}{
                        left: auto;
                        right: calc(100% + 50px);                    
                        transform: translateY(-50%) translateX(50%);
                    }
                    @media#{$xl}{
                        left: auto;
                        right: calc(100% + 76px);                    
                        transform: translateY(-50%) translateX(50%);
                    }
                }
                &::after{
                    @media#{$md}{
                        left: auto;
                        right: calc(100% + 50px);
                        transform: translateX(50%);
                    }
                    @media#{$xl}{
                        left: auto;
                        right: calc(100% + 76px);
                        transform: translateX(50%);
                    }
                } 
            }
        }
        &:last-child{
            .wrapper{
                &::after{
                    @media#{$md}{
                        display: none;  
                    }
                }
            }
        }
        &.active{
            .wrapper{
            background-color: $green;
            transition: 0.5s;
            &::before{
                @media#{$md}{
                background-color: $green-50;    
                transition: 0.5s;
                }
            }}
        }
        .wrapper{
            background-color: $green;
            margin: 30px 0;
            border-radius: 35px;
            position: relative;
            width: 100%;
            box-shadow: 0 0 15px rgba($black,0.15);
            transition: 0.5s;
            @media #{$md}{
                margin: 40px 0;
                background-color: $gray-40;
                box-shadow: 0 0 25px rgba($black,0.15);
                position: relative;
            }
            &.active{
                background-color: $green;
                &::before{
                    @media#{$md}{
                    background-color: $green-50;    
                    }
                }
            }
            &::before{
                @media#{$md}{
                    position: absolute;
                    content: "";
                    height: 20px;                
                    width: 20px;
                    background-color: $gray-30;
                    top: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    border-radius: 50%;
                    left: calc(100% + 50px);
                    z-index: 1;
                    transition: 0.5s;
                }
                @media#{$xl}{
                    left: calc(100% + 76px);
                    height: 24px;                
                    width: 24px;
                }
            }
            &::after{
                @media#{$md}{
                    position: absolute;
                    content: "";
                    height: calc(50% + 40px);
                    width: 2px;
                    left: calc(100% + 50px);
                    bottom: -40px;
                    transform: translateX(-50%);
                    background-image: linear-gradient(5turn,$gray-10,$gray-10 50%,$gray-30 0,$gray-30 );
                    background-size: 3px 20px, 100% 20px;
                }
                @media#{$xl}{
                    left: calc(100% + 76px);
                }
            }
            &:hover{
                a{
                    color: $yellow;
                    .title-icon{
                        .icon-wrapper{
                            border-color: $yellow;
                            svg{
                                fill: $yellow;
                                path{
                                    fill: $yellow !important;
                                }
                            }
                        }
                        .h4{
                            color: $yellow;
                        }
                    }
                    .content{
                        p{
                            color: $yellow;
                        }
                    }
                    svg{
                        fill: $yellow;
                    }
                }
            }
            > a{
                width: 100%;
                height: 100%;
                padding: 37px;
                padding-top: 50px;
                padding-bottom: 85px;
                color: $white;
                display: block;
                @media #{$md}{
                    padding-top: 45px;
                    padding: 45px;
                    padding-bottom: 95px;
                }                
            }
            .title-icon{
                display: flex;
                flex-direction: row;
                margin-bottom: 25px;
                @media #{$md}{
                    flex-direction: column;
                }
                .icon-wrapper{
                    margin: 0;
                    margin-right: 24px;
                    height: 42px;
                    width: 42px;
                    border: 2px solid #fff;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    @media #{$md} {
                        margin: 0 auto 25px;        
                        height: 65px;
                        width: 65px;
                    }
                    @media #{$xl} {
                        height: 80px;
                        width: 80px;
                    }
                    svg{
                        height: 16px;
                        width: 16px;
                        fill: $white;
                        @media #{$md}{
                            height: 26px;
                            width: 26px;
                        }
                    }
                }
                .h4{
                    color: $white;
                    line-height: 1.2;
                    font-size: 23px;
                    max-width: calc(100% - 70px);
                    @media #{$md}{
                        font-size: 31px;
                        max-width: 100%;
                    }
                }
            }
            .content{
                p{
                    font-size: 18px;
                    line-height: 1.4;
                    letter-spacing: 0.5px;
                    color: $white;
                }
            }
            .card-cta{
                position: absolute;
                bottom: 35px;
                left: 35px;
                right: auto;
                @media #{$md}{
                    bottom: 45px;
                    right: 45px;    
                    left: auto;
                }
            }
        }
    }
}
.inner-banner {
    + .about-list-section{
        margin-top: -50px;
        padding-top: 0;
        @media #{$md}{
            margin-top: -80px;
        }        
    }
    + .pattern-gredient{
        + .about-list-section{
            margin-top: -50px;
            padding-top: 0;
            @media #{$md}{
                margin-top: -80px;
            }        
        }
    }
}
.about-list-section{
    margin: 100px 0;
    position: relative;
    z-index: 1;
}
.about-grid-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .grid-item{
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        &:not(:last-child){
            margin-bottom: 48px;
        }        
        
        @media #{$md}{
            flex: 0 0 33.33%;
            max-width: 33.33%;    
            margin-bottom: 0px;
        }
        @media #{$lg}{
            padding: 0 40px;
        }
        .about-icon{
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background-color: $white;
            display: flex;
            align-content: center;
            justify-content: center;
            margin-bottom: 24px;
            box-shadow: 0 0 6px rgba($black, 0.10);
            @media #{$md}{
                margin-bottom: 35px;    
                height: 144px;
                width: 144px;
            }
            img{
                object-fit: contain;
                width: 36px;
                @media #{$md}{
                    width: 55px;    
                }
            }
        }
        .about-content{
            max-width: 250px;
            @media #{$sm}{
                max-width: 100%;
            }
            p{                
                line-height: 1.4;
                letter-spacing: 1px;
                color: $offBlack;
                @media #{$md}{
                    font-size: 16px;
                }
                @media #{$xl}{
                    font-size: 20px;
                }
            }
        }
    }
    
}
.campaign-detail-heading-section{
    margin-bottom: 90px;
    .content-wrapper{
        width: 100%;
        @media #{$md}{
            width: calc(100% - 300px);
        }
        @media #{$lg}{
            width: 70%;
        }
        
    }
    .campaign-date{
        font-size: 20px;
        color: $blue;
        margin-bottom: 20px;
    }
    .capaign-title{
        color: $offBlack;   
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    .capaign-sub-content{
        color: $offBlack;
        font-weight: 600;
    }
}

.campaign-section{
    margin: 60px 0;
    padding-top: 60px;
    margin-top: -60px;
    .section-heading{
        .h3{
            max-width: 580px;
            letter-spacing: 0;
            margin-bottom: 20px;
            text-align: center;
            @media #{$md}{
                text-align: left;
            }
        }
    }
    .campaign-items-wrapper{
        > .wrapper{
            display: flex;
            flex-wrap: wrap;
        }
        .parent-item{
            > div {
                display: none;
                // opacity: 0;
                visibility: hidden;
                animation: fadeOut ease 2s;
                -webkit-animation: fadeOut ease 2s;
                -moz-animation: fadeOut ease 2s;
                -o-animation: fadeOut ease 2s;
                -ms-animation: fadeOut ease 2s;
                &.active{
                    display: block;
                    // opacity: 2;
                    visibility: visible;
                    animation: fadeIn ease 2s;
                    -webkit-animation: fadeIn ease 2s;
                    -moz-animation: fadeIn ease 2s;
                    -o-animation: fadeIn ease 2s;
                    -ms-animation: fadeIn ease 2s;
                }
            }
        }
        .campaign-listing-wrapper{
            width: 100%;
            padding-left: 0;
            position: relative;
            z-index: 2;
            margin-bottom: 50px;
            @media #{$md}{
                padding-right: 20px;
                width: 50%;
                margin-bottom: 0px;
            }
            .mobile-drop-down-wrapper{
                @media (max-width: 767px){
                    border: 1px solid #1f1f1f;
                    border-radius: 7px;
                    box-shadow: 0 0 10px rgba(0,0,0,0.15);
                    > .mobile-drop-down{
                        border: 0;    
                    }
                    .campaign-listing{                   
                        position: relative;
                        margin-bottom: 0;
                        background-color: transparent;
                        box-shadow: none;
                        padding-top: 0;                 
                    }
                }
            }
            span.mobile-drop-down{
                display: block;
                border: 1px solid #1f1f1f;
                height: 50px;                
                font-size: 16px;
                line-height: 1.2;
                line-height: 48px;
                padding: 0 20px;
                border-radius: 7px;
                position: relative;
                z-index: 2;
                background:  $gray-10;
                
                @media #{$md}{
                    display: none;
                }
                &::before{
                    position: absolute;
                    content: '';
                    height: 24px;
                    width: 24px;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 8.17315L0 2.0507L1.4 0.622131L6 5.31601L10.6 0.622131L12 2.0507L6 8.17315Z' fill='%231F1F1F'/%3E%3C/svg%3E%0A");                    
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 12px;                                        
                }
            }
        }
        .campaign-listing{
            width: 100%;
            list-style: none;
            padding: 0;
            @media #{$md}{
                display: block !important;
            }
            @media (max-width: 767px){
                background-color:  $blue-10;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 20px 0;
                top: calc(100% - 5px);
                box-shadow: inset 0 0 4px rgba(0,0,0,0.15);
                position: absolute;
                display: none;
            }
            > li{
                max-width: 420px;
                color: $gray;
                font-size: 16px;
                line-height: 1.4;
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                @media #{$md}{
                    padding: 5px 0;
                    margin-bottom: 40px;
                    font-size: 25px;
                    // pointer-events: none;
                }
                @media #{$xl}{
                    margin-bottom: 60px;
                }
                > span{
                    max-width: calc(100% - 45px);
                    color: $offBlack;
                    @media #{$md}{
                        color: $gray;    
                    }
                }
                &.active{
                    color: $offBlack;
                    svg{
                        fill: $offBlack;
                    }
                    > span{
                        // color: $lightGreen;
                        color: $blue;                        
                        font-weight: 400;
                        position: relative;
                        @media (max-width: 767px){
                            &:after{
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: -32px;
                                content: '';
                                width: 16px;
                                height: 16px;
                                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_3109_2972' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'%3E%3Crect width='16' height='16' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_3109_2972)'%3E%3Cpath d='M6.36674 12L2.56674 8.2L3.51674 7.25L6.36674 10.1L12.4834 3.98334L13.4334 4.93334L6.36674 12Z' fill='%230961AD'/%3E%3C/g%3E%3C/svg%3E%0A");

                            }
                        }
                        @media #{$md}{
                            color: $offBlack;
                        }
                    }
                    
                }
                svg{
                    fill: $gray;
                }
                .icon-wrapper{
                    height: 45px;
                    width: 45px;
                    display: none;
                    @media #{$md}{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
        .campain-details{
            width: 100%;
            @media #{$md}{
                width: 50%;
            }           
            .h4{
                display: none;
                @media #{$md}{
                    display: block;
                }
            } 
            .player-wrapper{
                margin: 0 0 30px;
                padding-top: 56%;
                background-color: $gray;
                border-radius: 16px;
                overflow: hidden;
                position: relative;
                @media #{$md}{
                    margin: 30px 0;
                }
                @media #{$lg}{
                    margin: 48px 0;
                }
                iframe, video{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 0;
                }
            }
            .compaign-sub-content-wrapper{
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                @media (max-width: 1024px) and (min-width : 768px){
                    flex-wrap: wrap;
                }
                p{
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    @media #{$md}{
                        padding-right: 40px;    
                    }
                }
                .btn{
                    display: none;
                    @media #{$md}{
                        display: block;
                    }
                    white-space: nowrap;
                    @media (max-width: 1024px) and (min-width : 768px){
                        margin-top: 20px;
                    }
                }
            }
        }
        .campaign-counters-wrapper{
            width: 100%;
            margin-top: 40px;
            @media #{$md}{
                margin-top: 90px;
            }
            .counters-wrapper{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                @media  #{$md}{
                    flex-wrap: nowrap;
                    justify-content: space-between;
                }
            }
            .counter-item{
                max-width: 300px;
                width: 100%;
                text-align: center;
                padding: 0 20px;
                margin-bottom: 25px;
                @media #{$sm}{
                    max-width: 100%;
                    margin-bottom: 0px;
                }
                &:nth-child(4){
                    margin-bottom: 0;
                }
                .number{
                    @media (max-width: 767px){
                        font-size: 49px;
                        line-height: 1;
                    }
                }
                .text{
                    font-size: 16px;
                    @media #{$sm}{
                        font-size: 18px;
                    }                    
                    @media #{$xl}{
                        font-size: 25px;    
                    }
                }
                span{
                    letter-spacing: 0;
                }
            }
            .primary-btn{
                width: 100%;
                @media (max-width: 767px){
                    max-width: 100%;
                    margin: 40px auto 0;
                }
                @media #{$sm}{
                    width: auto;
                }
                @media #{$md}{
                    display: none;
                }
            }
        }
    }
}
@keyframes fadeIn{
    0% { opacity:0; }
    100% { opacity:1; }
}

@keyframes fadeOut{
    0% { opacity:1; }
    100% { opacity:0; }
}

.campaigns-detail-content{
    .campaign-content-wrapper{        
        max-width: 100%;
        margin-bottom: 50px;
        @media #{$sm}{
            column-count: 2;
            column-gap: 40px;
            margin-bottom: 80px;
        }
        @media #{$md}{
            max-width: calc(100% - 300px);
            column-count: 1;
        }
        @media #{$lg}{
            max-width: 70%;    
            column-count: 2;
        }
    }
    .h4{
        margin-bottom: 26px;
        color: $low-light;
        @media (max-width: 575px){
            font-size: 21px;
        }
    }
    .campaign-content{
        p{
            margin-bottom: 20px;
            font-size: 18px;
            color: $offBlack;
            letter-spacing: 0;
            margin-bottom: 16px;
            line-height: 1.25;
        }
    }
    .campaign-testimonial{
        border-top: 1px solid $low-light-40;
        border-bottom: 1px solid $low-light-40;
        padding: 50px 0;
        margin-top: 32px;
        .testimonial-content{
            p{
                font-size: 21px;
                color: $low-light;
                line-height: 1.2;
                letter-spacing: 0;
                @media #{$sm}{
                    font-size: 25px;
                }
            }            
        }
        .testimonial-author{
            margin-top: 15px;
            font-size: 18px;
            color: $offBlack;
            letter-spacing: 0;
        }
    }
    .campaign-image-wrapper{
        margin-top: 50px;
        &.full-width-image{
            column-span: all;
            @media #{$sm}{
                margin-top: 80px;    
            }
            .image-wrapper{
                padding-top: 75%;
                @media #{$sm}{    
                    padding-top: 33%;
                }
                &.video-wrapper{
                    padding-top: 60%;    
                }
            }    
        }
        .image-wrapper{
            padding-top: 75%;
            border-radius: 35px;
            overflow: hidden;
            position: relative;
            img{
                position: absolute;
                top : 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            iframe , video{
                position: absolute;
                top : 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border:0;
            }
        }
    }
    .content-divider {
        column-span: all;
        margin-top: 50px;
        border-color: transparent;
        background-color: $gray-40;
        @media #{$sm}{
            margin-top: 80px;
        }
    }
}
.inner-banner{
    + .campaigns-detail-wrapper{
        margin-top: -220px;
    }
}
.campaigns-detail-wrapper{
    background-color: $low-light-10;
    .content-wrapper{
        position: relative;
        max-width: 960px;        
        margin: 0 auto;
        padding-bottom : 72px;
        z-index: 1;
        @media #{$md}{            
            padding: 0 20px;
            padding-bottom : 140px;
        }
        @media #{$xl}{
            padding-bottom : 180px;
        }
    }
    .campaign-banner{        
        margin-bottom: 50px;
        padding: 0 15px;
        @media #{$md}{
            margin: 0 -20px;
            margin-bottom: 80px;
            padding: 0;
        }
        .image-wrapper{
            padding-top: 100%;
            border-radius: 25px;
            overflow: hidden;
            position: relative;
            background-color: $gray-10;
            @media #{$md}{
                padding-top: 60%;    
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            iframe , video{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }
    }
    .campaign-content{
        margin-bottom: 50px;
        @media #{$md}{
            margin-bottom: 80px;
        }
       
        .section-haeding{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 40px;
            @media #{$md}{
                margin-bottom: 50px;
            }
            .h2{
                width: 100%;
                @media #{$md}{
                    max-width: calc(100% - 250px);
                }
            }
            .primary-btn{
                align-self: flex-end;
                @media (max-width: 767px){
                    display: none;
                }
            }
        }
        .campaign-date {
            text-transform: uppercase;
            font-size: 16px;
            line-height: 1;
            font-weight: 500;
            color: $blue;
            margin-bottom: 20px;
            @media #{$md}{
                font-size: 20px;
                margin-bottom: 32px;
            }
        }
        .content{
            column-gap: 70px;
            font-size: 18px;
            color: $offBlack;
            @media #{$md}{
                column-count: 2;
            }
            p {
                font-size: 18px;
                color: $offBlack;
            }
        }
    }
    .campaign-counter {        
        border-radius: 30px;        
        @media #{$md}{
            padding: 40px;
            background-color: $white;
            box-shadow: 0 0 25px rgba($black, 0.15);
        }
        @media #{$lg}{
            padding: 80px;
        }        
        .counter-items-wrapper{
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            row-gap: 48px;
            .counter-item{
                max-width: 100%;
                flex: 0 0 100%;
                @media #{$md}{
                    max-width: 50%;
                    flex: 0 0 50%;
                }
                text-align: center;
                .number{
                    @media (max-width: 767px){
                        font-size: 42px;
                        line-height: 1;
                        margin-bottom: 10px;
                    }
                    span{
                        line-height: 34px;
                        display: inline-block;
                        @media (max-width: 767px){
                            font-size: 42px;
                            line-height: 1;
                        }
                    }
                }
                .text{
                    font-size: 16px;
                    line-height: 1.2;                    
                    @media #{$md}{
                        margin-top: 10px;
                        font-size: 25px;    
                    }
                }
            }
        }
        .counter-content{
            margin-top: 48px;
            text-align: center;
            color: $offBlack;
            font-size: 14px;
            font-weight: 400;
        }
        .primary-btn{
            width: 100%;
            margin-top: 60px;
            @media #{$md}{
                width: auto;
                margin-top: 0px;
            }
        }
    }
}
.membership-block-section{
    + .faq-section-wrapper{
        .bg-shape{
            @media #{$md}{
                top: 80%;
            }
        }
    }    
}
.faq-section-wrapper{
    margin: 100px 0;
    @media #{$md}{
        margin: 190px 0;
    }
    .section-heading{
        .h2{
            @media (max-width: 767px){
                text-align: left;
            }
        }
    }
    &.bg-health-shape{
        position: relative;
        .bg-shape{
            position: absolute;
            right: 0;
            width: 100%;
            top : 100%;
            transform: translateY(-80%);
            overflow: hidden;
            max-width: 450px;
            @media #{$md}{
                max-width: min(900px , 60%);
                top : 50%;
                transform: translateY(-50%);	
            }
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .content-wrapper{
        max-width: 930px;
        margin: 0 auto;
        position: relative;
    }
    .accordion-item{        
        padding: 32px 0 0;
        transition: max-height 0.3s ease-out;
        &:not(:last-child){
            border-bottom: 1px solid $gray-40;
        }
        &.accordion-item-active{
            .accordion-answer{
                visibility: visible;
                max-height: 1000px; /* Set a high value to ensure content is fully visible */
                padding-bottom: 32px;
            }
            .accordion-question{

                &:after{
                    transform: translateY(-50%) rotate(90deg);
                }
            }
        }
    }
    .accordion-question{
        font-size: 20px;
        line-height: 1.2;
        font-weight : 500;
        color: $offBlack;
        margin-bottom: 32px;
        position: relative;
        padding: 8px 0;
        padding-right: 45px;
        cursor: pointer;
        &:after{
            position: absolute;
            content: "";
            height : 40px;
            width: 40px;
            background-color: transparent;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='13' viewBox='0 0 20 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 12.6248L0 2.62484L2.33333 0.291504L10 7.95817L17.6667 0.291504L20 2.62484L10 12.6248Z' fill='%231F1F1F'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            transition: 0.5s;
        }
    }
    .accordion-answer{
        font-size: 20px;
        max-height: 0;
        padding: 0;
        overflow: hidden;
        transition: 0.5s;
        visibility: hidden;
        font-size: 16px;
        line-height: 1.2;
        p{
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 1.2;
        }
        h1,.h1,
        h2,.h2,
        h3,.h3,
        h4,.h4,
        h5,.h5,
        h6,.h6{
            margin-bottom: 20px;
            margin-top: 35px;
        }
    }
}

.follow-us-section{
    margin: 150px 0;
    .content-wrapper{
        max-width: 930px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        padding: 0 20px;
        @media #{$md}{
            padding: 0;
        }
    }
    .section-heading{
        margin-bottom: 80px;
    }
    .social-icons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        list-style: none;
        align-items: center;
        padding: 0;
        margin: 0;
        gap : 60px;
        @media #{$md}{
            gap : 0;
            justify-content: space-between;
        }        
        li{
            height : 57px;
            width : 57px;
            svg{
                height : 57px;
                width : 57px;
                fill: $blue;
                transition: 0.5s;
            }
        }
        .social-links{
            &:hover{
                svg{
                    fill: $low-light;
                    transition: 0.5s;
                }                
            }
        }
    }
}
.post-listing-with-pagination{
    + .get-in-touch-section{
        padding: 0;
        margin: 80px 0 200px;
        @media #{$sm}{
            margin: 140px 0 250px;
        }
        @media #{$lg}{
            margin: 190px 0 300px;
        }        
    }
}
.faq-section-wrapper{
    + .get-in-touch-section{
        padding: 0;
        margin: 80px 0 200px;
        @media #{$sm}{
            margin: 120px 0 250px;
        }
        @media #{$lg}{
            margin: 160px 0 300px;
        }        
    }
}
.get-in-touch-section{
    padding: 70px 0 200px;
    position: relative;
    background-color: $low-light-20;
    overflow: hidden;
    z-index: 1;
    @media #{$sm}{
        padding: 120px 0 250px;
    }
    @media #{$lg}{
        padding: 160px 0 300px;
    }
    @media #{$xl}{
        padding: 250px 0 300px;    
    }
    &.form-blue-bg{
        background-color: transparent;    
        .bg-shape-wrapper{
            img{
                opacity: 0.1;
            }
        }
        .form-content-wrapper{
            background-color: transparent;
            background-image: $blueGradient;
        }
        .h2,
        .sub-heading{
            color: $white;
        }
        .form-wrapper{
            .gform_wrapper{
                .form-control{
                    label{
                        color : $white;
                    }
                    // input,textarea,select{
                    //     border-color: $white;
                    // }
                }
                .gform_footer{
                    .gform_button{
                        background-color: $yellow;
                        color: $offBlack;
                        &:hover{
                            background-color: $yellow-80;
                        }
                    }
                } 
            }
        }
    }
    .bg-shape-wrapper{
        position: absolute;
        max-width: 80%;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
        display: none;
        left: auto;
        right: 170px;
        max-width: 1540px;
        margin-right: calc(50vw - calc(1300px / 2 ));
        &.white-logo{
            left: auto;
            right: 170px;
            max-width: 1540px;
            margin-right: calc(50vw - calc(1300px / 2 ));
            img{
                opacity: 1;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top right;
            opacity: 0.3;
        }
        @media #{$md}{
            display: block;
        }
    }
    .form-heading{
        text-align: center;
        margin-bottom: 40px;
        @media #{$md}{
            text-align: left;
        }
        .sub-heading{
            margin-top: 16px;
            font-weight: 500;
        }
    }
    .form-content-wrapper{
        background-color: $white;
        border-radius: 35px;
        box-shadow: 0 0 25px rgba($black , 0.15);
        padding: 48px 32px;
        max-width: 930px;
        margin: 0 auto;
        position: relative;
        @media #{$md}{
            padding: 60px;
        }
        @media #{$lg}{
            padding: 88px;
        }
    }
    .form-wrapper{
        .gform_wrapper{
            .gform_heading{
                display: none;
            }
            .gform_fields{
                grid-row-gap: 0;
                @media #{$sm}{
                    grid-column-gap: 32px;    
                }
                .gfield{
                    position: relative;
                }
            }
            .form-control{
                label{
                    font-size: 16px;
                    line-height: 1.2;
                    margin-bottom: 20px;
                    font-weight: 500;
                    padding: 0;
                    display: none;
                    @media (min-width: 640px){
                        display: block;
                    }
                    @media #{$xl}{
                        font-size: 20px;    
                    }
                }
                .ginput_complex {
                    span {
                        margin: 0;
                    }
                }
                .name_first{
                    @media (min-width: 640px){
                        padding-right: 16px !important;
                    }
                }
                .name_last{
                    @media (min-width: 640px){
                        padding-left: 16px !important;
                    }
                }
                input{
                    border: 1px solid $offBlack;
                    background-color: #fff;
                    height: 50px;
                    width: 100%;
                    margin-bottom: 30px;
                    padding: 8px 14px;
                    font-size: 16px;
                    line-height: 1.2 !important;
                    border-radius: 10px;
                    outline: none;
                    @media #{$md}{
                        height: 60px;
                        border-radius: 20px;
                        margin-bottom: 40px;
                    }
                    &::placeholder{
                        @media (min-width: 640px){
                            color: transparent !important;
                        }
                    }
                    &[type="radio"] {
						width: 24px;
						height: 24px;
						background-color: $white;
						border-radius: 5px;
						margin-right: 30px;
						display: inline-block !important;
						border: 1px solid $black;
						&:checked{
							background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0501 13L0.350098 7.30001L1.7751 5.87501L6.0501 10.15L15.2251 0.975006L16.6501 2.40001L6.0501 13Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
							background-position: center;
							background-repeat: no-repeat;
						}
					}
                }

                select{
                    border: 1px solid $offBlack;
                    background-color: #fff;
                    height: 50px;
                    width: 100%;
                    margin-bottom: 30px;
                    padding: 8px 14px;
                    font-size: 16px;
                    line-height: 1.2 !important;
                    border-radius: 10px;
                    outline: none;
                    background-image: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.10811 10L0 1.89189L1.89189 0L8.10811 6.21622L14.3243 0L16.2162 1.89189L8.10811 10Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
                    background-size: 16px;
                    background-position: 97%;
                    background-repeat: no-repeat;
                    outline: none;
                    @media #{$md}{
                        height: 60px;
                        border-radius: 20px;
                        margin-bottom: 40px;
                    }
                }
                textarea{
                    border: 1px solid $offBlack;
                    background-color: #fff;
                    width: 100%;
                    margin-bottom: 30px;
                    padding: 8px 14px;
                    font-size: 16px;
                    line-height: 1.2 !important;
                    border-radius: 10px;
                    resize: none;
                    outline: none;
                    @media #{$md}{
                        border-radius: 20px;
                        margin-bottom: 40px;
                        height: 120px !important;
                    }
                    &::placeholder{
                        @media (min-width: 640px){
                            color: transparent !important;
                        }
                    }
                }
                &.sincerely-content{
                    &.show-label{
                        label{
                            display: block;
                            font-size: 18px;
                            line-height: 1.4;
                            margin-bottom: 5px;
                        }
                    }
                    label{
                        display: none;
                    }
                    input{
                        background-color: transparent;
                        color: $white;
                        padding: 0;
                        margin: 0;
                        margin-bottom: 5px;
                        color: $white;
                        border: 0;
                        height: auto;
                        pointer-events: none;
                        border-radius: 0;
                        font-size: 18px;
                        line-height: 1.4;
                    }
                }
            }
            .gfield--type-checkbox{
                .gfield_checkbox{
                    margin-bottom: 40px;
                    
                    input[type="checkbox"] {
                        width: 24px;
                        height: 24px;
                        background-color: $white;
                        border-radius: 5px;
                        margin-right: 30px;
                        cursor: pointer;
                        &:checked{
                            background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0501 13L0.350098 7.30001L1.7751 5.87501L6.0501 10.15L15.2251 0.975006L16.6501 2.40001L6.0501 13Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        + label{
                            cursor: pointer;
                            max-width: calc(100% - 60px);
                            font-size: 16px;
                            line-height: 1.2;
                            color: $white;
                            font-weight: 500;
                            @media #{$xl}{
                                font-size: 20px;    
                            }
                        }
                    }
                }
            }
            .gform_footer{
                padding: 0;
                margin: 0;
                justify-content: center;
                position: relative;
                .gform_button{
                    display: inline-block;
                    padding: 18px 45px;
                    margin-top: 20px;
                    font-size: 14px;
                    line-height: 1.1 !important;
                    border-radius: 50px;
                    color: $white;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    text-align: center;
                    box-shadow: 0 0 25px 0  rgba($black , 0.25);
                    background-color: $blue;
                    transition: 0.5s;
                    border: 0;
                    width: 100%;
                    cursor: pointer;
                    @media #{$md}{
                        width: auto;
                    }
                    &:hover{
                        background-color: $blue-80;
                    }
                }
                .gform_ajax_spinner {
                    position: absolute;
                    top: 50%;
                    left: 0;
                }
            }
        }        
    }    
}

.gform_wrapper{
    &.gravity-theme {
        .gfield_validation_message, .validation_message{
            padding: 5px 15px !important;
            border-radius: 5px;
            line-height: 1 !important;
            border: 0;
            transform: translateY(-35px);
            font-size: 12px !important;
            position: absolute;
        }
    }
}
.inner-banner{
    + .get-in-touch-section{
        margin: 150px 0;
        margin-top: -280px;
        padding-top: 0;
        padding-bottom: 40px;
        background-color: transparent;
        @media (max-width: 767px){
            margin-bottom: 80px;
        }
        .bg-shape-wrapper{
            display: none;
        }
    }
}

.campaigns-detail-content {
    + .get-involve-section{
        margin: 80px 0;
    }
}
.get-involve-section{
    background: $blueGradient;
    padding: 80px 0;
    margin: 120px 0;
    position: relative;
    overflow: hidden;
    @media #{$md}{
        padding: 120px 0;
        margin: 190px 0;
    }
    &.rounded-top-left{
        border-top-left-radius: 0px;
        @media #{$md}{
            border-top-left-radius: 55px;
        }
    }
    &.rounded-top-right{
        border-top-right-radius: 0px;
        @media #{$md}{
            border-top-right-radius: 55px;
        }
    }
    &.rounded-bottom-left{
        border-bottom-left-radius: 0px;
        @media #{$md}{
            border-bottom-left-radius: 55px;
        }
    }
    &.rounded-bottom-right{ 
        border-bottom-right-radius: 0px;
        @media #{$md}{
            border-bottom-right-radius: 55px;
        }
    }
    &.content-left{
        .bg-shape-wrapper{
            @media #{$md}{
                left: auto;
                right: 0;
            }
        }
        .get-involved-content{
            margin-left: 0;
        }
        .bg-shape-wrapper{
            img{
                @media #{$md}{
                    object-fit: contain;
                    object-position: right bottom;
                }                
                @media #{$xl}{
                    object-fit: cover;
                    object-position: center;    
                }
            }
        }
    }
    .bg-shape-wrapper{
        position: absolute;
        top: auto;
        bottom: -6px;
        left: 0;
        max-height: 250px;
        height: 100%;
        max-width: 100%;
        width: 100%;
        pointer-events: none;
        @media #{$md}{
            max-height: 120%;
            max-width: 50%;    
            top: 50%;
            transform: translateY(-50%);
            height: 120%;
        }
        img{
            position: absolute;
            opacity: 0.3;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left bottom;            
            @media #{$xl}{
                object-fit: contain;
                object-position: center right;    
            }
        }
    }
    .get-involved-content{
        max-width: 100%;
        margin-left: auto;
        padding: 0 10px;
        @media #{$md}{
            max-width: 70%;
        }
        @media #{$xl}{
            max-width: 50%;
        }
        .wrapper{
            position: relative;
        }
        .content {
            border: 1px solid #fff;
            border-radius: 35px;
            padding: 40px;
            text-align: center;
            @media #{$md}{
                text-align: left;
                padding-bottom: 130px;
            }
            @media #{$xl}{
                padding: 56px;                
                text-align: left;
                padding-bottom: 130px;
            }
        }
        .h3{
            margin-bottom: 24px;
            color: #fff;
            font-weight: 600;
            font-size: 23px;
            @media #{$md}{
                font-size: 39px;
            }
        }
        p{
            font-size: 18px;
            letter-spacing: 0;
            color: #fff;
            font-weight: 400;
        }
        .btn{
            position: static;
            margin-top: 54px;
            width: 100%;
            @media #{$md}{
                position: absolute;
                bottom: 40px;
                left: 40px;
                width: auto;
            }
            @media #{$xl}{
                left: 56px;
                bottom: 56px;
            }
        }
    }
}
.hero-banner{
    margin-bottom: 100px;
    position: relative;
    // min-height: 650px;
    @media #{$md}{
        margin-bottom: 180px;
    }
    &::before{
        position: absolute;
        content: '';        
        background: $greenGradient;
        top: 0;
        left: 0;
        right: 0;
        border-bottom-right-radius: 55px;
        border-bottom-left-radius: 55px;
        height: calc(100% - 80px);
        @media #{$md}{
            height: 100%;
        }        
    }
    .content-wrapper{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;
        @media #{$md}{
            padding-top: 55px;
        }
        .left-part{
            width: 100%;
            margin-bottom: 56px;
            @media #{$md}{
                width: 50%;
                margin-bottom: 0px;
                padding-right: 20px;
            }            
            .wrapper{
                max-width: 570px;                
                margin: 0 auto;
                text-align: center;
                @media #{$md}{
                    margin: 0;
                    text-align: left;                    
                    padding-bottom: 50px;
                }
                @media (min-width: 991px){
                    padding-top: 22px;
                }
                @media #{$xl}{
                    padding-bottom: 100px;
                }
                .banner-heading {
                    margin-bottom: 24px;
                    color: $white;
                    margin-top: 0;
                    @media #{$xl}{
                        margin-bottom: 30px;
                    }
                    @media (max-width: 899px){
                        font-size: 38px;
                    }
                    @media (max-width: 640px){
                        font-size: 32px;
                    }
                }
                .banner-cta{
                    width: calc(100% - 40px);
                    margin-top: 60px;
                    @media #{$sm}{
                        width: auto;
                    }
                    @media #{$md}{
                        margin-top: 30px;
                    }
                    @media #{$lg}{
                        margin-top: 60px;
                    }
                }
            }
            .sub-content{
                max-width: 465px;
                color: $white;
                font-size : 16px;
                margin: 0 auto;
                @media #{$md}{
                    margin: 0;
                }                
                @media #{$lg}{
                    font-size: 18px;
                }
                // @media #{$xl}{
                //     font-size : 25px;
                // }               
                
            }
        }
        .right-part{
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            position: relative;
            @media #{$md}{
                width: 50%;
                padding-right: 0px;
                padding-left: 15px;
            }       
        }
        .image-wrapper{
            padding-top: 100%;
            position: relative;
            border-radius: 35px;
            overflow: hidden;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
            @media #{$md}{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%
            }
            img{
                position: absolute;
                top:0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
.image-and-text-section{
    margin: 120px 0;
    .grid-wrapper{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0 16px;        
        @media #{$lg}{
            padding: 0 40px;
        }
        @media #{$xl}{
            padding: 0 80px;
        }        
        &.row-reverce{
            flex-direction: row-reverse;
            .left-part {
                padding-right: 0px;
                @media #{$md}{
                    padding-left: 30px;
                }
                @media #{$lg}{
                    padding-left: 55px;
                }
            }
        }
    }
    .left-part {
        flex: 0 0 100%;
        max-width: 100%;
        @media #{$md}{
            flex: 0 0 60%;
            max-width: 60%;
            padding-right: 30px;
        }
        @media #{$lg}{
            padding-right: 55px;
        }
        .image{
            margin-bottom: 32px;
            @media #{$md}{
                max-width: 600px;
                margin-bottom: 0;
            }
            .image-wrapper{
                padding-top: 100%;
                position: relative;
                border-radius: 35px;
                overflow: hidden;
                box-shadow: 0 0 25px rgba($black,0.15);
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .right-part {
        flex: 0 0 100%;
        max-width: 100%;
        @media #{$md}{
            flex: 0 0 40%;
            max-width: 40%;
        }
        .content-wrapper{
            .tag-line{
                font-size: 16px;
                font-weight: 500;
                color: $blue;
                line-height: 1.4;
                // margin-bottom: 16px;
                margin-bottom: 6px;
                @media #{$lg}{
                    font-size: 20px;
                    margin-bottom: 24px;
                }
            }
            .h2{
                margin-bottom: 32px;
                color: $offBlack;
            }
            .content{
                font-size: 18px;
                font-weight: 400;
                color: $offBlack;
                line-height: 1.4;
                
            }
            .primary-btn{
                margin-top: 32px;
                width: 100%;
                @media #{$md}{
                    width: auto;
                }
            }
        }
    }
}
.inner-banner{
    position: relative;
    margin-bottom: 70px;
    z-index: 1;
    @media #{$md}{
        margin-bottom: 180px;
    }
    &.fullwidth-wrapper{
        margin-bottom: 60px;    
        padding: 25px 0 120px;
        @media #{$md}{
            padding: 55px 0 160px;
        }
        .content-wrapper{
            padding-top: 0;
        }
        &::before{
            height: 100%;
        }
    }
    &.small-spacing{
        padding-bottom: 120px;
    }
    &.medium-spacing{
        padding-bottom: 140px;
        @media #{$md}{
            padding-bottom: 240px;
        }
    }
    &.large-spacing{
        padding-bottom: 290px;
        @media #{$md}{
            padding-bottom: 350px;
        }
    }
    &::before{
        position: absolute;
        content: '';        
        background: $greenGradient;
        top: 0;
        left: 0;
        right: 0;
        border-bottom-right-radius: 55px;
        border-bottom-left-radius: 55px;
        height: calc(100% - 80px);
        @media #{$md}{
            height: 100%;
        }
    }
    &.mb-0{
        margin-bottom: 0;
    }
    .content-wrapper{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 50px;
        .fullwidth-part{
            .wrapper{
                max-width: 900px;
                margin: 0 auto;
                text-align: center;
                .banner-heading {
                    margin-bottom: 24px;
                    margin-top: 0;
                    color: $white;
                    @media #{$xl}{
                        margin-bottom: 30px;
                    }
                }
                .banner-cta{
                    margin-top: 60px;
                    width: calc(100% - 40px);
                    @media #{$sm}{
                        width: auto;
                    }
                }
                .sub-content{
                    color: $white;
                    font-size : 16px;                
                    @media #{$md}{
                        margin: 0;
                    }                
                    @media #{$lg}{
                        font-size: 18px;
                    }
                    // @media #{$xl}{
                    //     font-size : 25px;
                    // }               
                    
                }
            }
            .sub-content{
                margin: 0 auto;
                max-width: 850px;
            }
        }
        
        .left-part{
            width: 100%;
            margin-bottom: 56px;
            @media #{$md}{
                width: 50%;
                margin-bottom: 0px;
                // min-height: 350px;
                padding-right: 20px;
            }   
            // @media #{$xl}{
            //     min-height: 500px;
            // }
            
            .wrapper{
                max-width: 560px;                
                margin: 0 auto;
                text-align: center;
                @media #{$md}{
                    margin: 0;
                    text-align: left;
                    padding-top: 0;
                    padding-bottom: 20px;
                }
                @media #{$lg}{
                    padding-top: 22px;
                    padding-bottom: 50px;
                }
                @media #{$xl}{
                    padding-bottom: 100px;
                }
                .banner-heading {
                    margin-bottom: 24px;
                    color: $white;
                    margin-top: 0;
                    @media #{$xl}{
                        margin-bottom: 30px;
                    }
                    @media (max-width: 640px){
                        font-size: 32px;
                    }
                }
                .banner-cta{
                    width: calc(100% - 40px);
                    margin-top: 60px;
                    @media #{$sm}{
                        width: auto;
                    }
                }
            }
            .sub-content{
                max-width: 465px;
                color: $white;
                font-size : 16px;
                margin: 0 auto;
                @media #{$md}{
                    margin: 0;
                }                
                @media #{$lg}{
                    font-size: 20px;
                }
                @media #{$xl}{
                    font-size : 25px;
                }               
                
            }
        }
        .right-part{
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            position: relative;
            @media #{$md}{
                width: 50%;
                padding-right: 0px;
                padding-left: 0px;
                margin-bottom: -120px;
            }
            @media #{$lg}{
                padding-left: 15px;
            }
            .image-wrapper{
                padding-top: 100%;
                position: relative;
                border-radius: 35px;
                overflow: hidden;
                box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
                @media #{$md}{
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    
                }
                img{
                    position: absolute;
                    top:0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
.inner-banner{
    + .latest-news-section{
        margin-top: -220px;
        @media #{$md}{
            margin-top: -280px;    
        }        
    }
}
.latest-news-section{
    overflow: hidden;
    &.layout_option_1{
        position: relative;
        .new-content-wrapper{
            max-width: 1050px;
            margin: 0 auto;
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
        &.shape-bottom{
            &::after{
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 160px;
                border-top-right-radius: 35px;
                border-top-left-radius: 35px;
                // background: $lightGreenGradient;
                background-color:#ccdddc;
                z-index: 0;
                @media #{$md}{
                    border-top-right-radius: 55px;
                    border-top-left-radius: 55px;    
                }
            }
        }
        
    }
    &.layout_option_2{
        padding: 70px 0;
        background : $lightGreenGradient;
        @media #{$md}{
            padding: 120px 0;
        }
    }
    &.rounded-top-left{
        border-top-left-radius: 35px;
        @media #{$md}{
            border-top-left-radius: 55px;
        }        
    }
    &.rounded-top-right{
        border-top-right-radius: 35px;
        @media #{$md}{
            border-top-right-radius: 55px;
        }
    }
    &.rounded-bottom-left{
        border-bottom-left-radius: 35px;
        @media #{$md}{
            border-bottom-left-radius: 55px;
        }
    }
    &.rounded-bottom-right{ 
        border-bottom-right-radius: 35px;
        @media #{$md}{
            border-bottom-right-radius: 55px;
        }
    }
    .section-heading{
        position: relative; 
        .btn{
            @media (max-width: 767px){
                display: none;
            }
        }
    }
    .new-content-wrapper{
        position: relative;
        padding: 20px 0;
        .news-slider-wrapper{
            &::after{
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                right: -24px;
                width: 40px;
                height: 100%;
                background: linear-gradient(90deg , rgba($low-light-20, 0) , rgba($white, 1));
                @media #{$md}{
                    display: none;
                }
            }
            .slick-list {
                padding-right: 60px;
                overflow: visible;
                
            }
            .slick-track{
                display: flex;
            }
        }
    }
    .latest-news-slider-wrapper {
        @media #{$md}{
            display: grid;
            grid-template-columns: 40% 60%;
            margin: 0 -10px;
        }   
        @media #{$lg}{
            margin: 0 -20px;
        }
        .slick-list{
            padding-right: 40px !important;
            overflow: visible;
        }
        .slick-track {
            display: flex;
        }
        .new-item {
            padding: 0 10px;
            height : auto;
            @media #{$lg}{
                padding: 0 20px;
            }            
            &:not(:last-child){
                @media #{$md}{
                    margin-bottom: 20px;
                }
                @media #{$lg}{
                    margin-bottom: 40px;
                }
            }
            &:nth-child(1) {                
                grid-row: span 3;
                @media #{$md}{
                    margin-bottom: 0px;
                }
                
                .wrapper{
                    flex-direction: column;
                    height: 100%;
                    .new-image {
                        position: relative;
                        padding-top: 45%;
                        max-width: 100%;
                        height: 100%;
                        @media #{$md}{
                            padding-top: 75%;    
                        }
                    }
                    .news-content{
                        padding: 30px 25px;
                        max-width: 100%;
                        flex: 1;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        @media #{$md}{
                            padding: 32px;
                            padding-bottom: 52px;
                        }
                    }
                    .news-cta{
                        bottom: 32px;
                        right: 25px;
                        @media #{$md}{
                            bottom: 52px;
                            right: 32px;
                        }                        
                    }
                }
            }
            .wrapper{
                display: flex;
                flex-direction: column;
                border-radius: 25px;
                overflow: hidden;
                background-color: $white;
                height: 100%;
                @media #{$md}{
                    flex-direction: row;    
                }
                &:hover{
                    background-color: $yellow-30;    
                }
            }
            .new-image {                
                position: relative;
                padding-top: 45%;
                max-width: 100%;
                @media #{$md}{
                    position: relative;
                    max-width: 150px;
                    width: 100%;
                    padding-top: unset;
                }
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            .news-content{
                padding: 30px 25px;
                max-width: 100%;
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                flex: 1;
                @media #{$md}{
                    max-width: calc(100% - 150px);
                    padding: 25px 32px;

                }
                .h6{
                    letter-spacing: 0.5px;
                    margin-bottom: 15px;
                    @media #{$md}{
                        margin-bottom: 20px;
                    }
                    @media (max-width: 767px){
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
            .news-category-wrapper{
                margin-bottom: 15px;
                display: flex;
                flex-wrap: wrap;
                gap : 15px;
                @media #{$md}{
                    margin-bottom: 20px;
                }
            }
            .news-category{
                color : $low-light;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 400;
                position: relative;
                &:not(:last-child){
                    &:after{
                        position: absolute;
                        content: '|';
                        color : $low-light;     
                        font-size: 12px;
                        font-weight: 500;
                        right: -8px;
                        width: 2px;
                        text-align: center;
                    }
                }
            }
            .news-date{
                color : $low-light;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 400;
                margin-top: auto;
            }
            .news-cta{
                position: absolute;
                bottom: 32px;
                right: 25px;
                height: 14px;
                width: 14px;
                @media #{$md}{
                    bottom: 24px;
                    right: 32px;    
                }
                svg{
                    height: 14px;
                    width: 14px;
                }
            }
        }
    }
    .latest-news-item {
        margin-bottom: 50px;
        .news-item {
            position: relative;
            border-radius: 35px;
            overflow: hidden;
            display: block; 
            &:hover{
                .news-content {
                    .news-heading-cta{
                        .h6{
                            color: $yellow-30;
                        }  
                        svg,path{
                            fill: $yellow-30;
                        } 
                    } 
                }
            }
        }
        .news-content {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 30px 30px;
            padding-top: 0;
            @media #{$md}{
                padding: 65px 70px;    
            }
            .section-title{
                margin-bottom: 35px;   
                color: $white;
                @media (max-width: 767px){
                    font-size: 24px;
                    margin-bottom: 25px;
                }
            }
            .news-date{
                font-size: 18px;
                color: $white;
                font-weight: 400;
                margin-bottom: 35px;
                @media (max-width: 767px){
                    font-size: 14px;    
                    margin-bottom: 25px;
                }
            }
            
            .news-heading-cta{
                display: flex;
                align-items: center;
                justify-content: space-between;
                
                .h6{
                    max-width: 560px;
                    font-size: 16px;
                    color: $white;
                    font-weight: 400;
                    letter-spacing: 2px;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    padding-right: 20px;
                    @media #{$md}{
                        font-size: 25px;    
                        padding-right: 0;
                    }
                }
                .news-cta{
                    svg{
                        height: 24px;
                        width: 24px;
                        fill: $white;
                        @media #{$md}{
                            height: 54px;
                            width: 54px;
                        }
                    }
                }
            }
        }
        .news-image {
            position: relative;
            padding-top: 48%;
            @media (max-width: 767px){
                padding-top: 100%;
            }
            &::after {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($black,0.45);
                pointer-events: none;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                pointer-events: none;
            }
        }

    }
    .news-slider-wrapper{
        @media #{$md}{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -16px;
        }
        .news-item{
            // flex: 0 0 100%;
            // max-width: 100%;
            padding: 0 10px;
            height: auto;
            @media #{$md}{
                flex: 0 0 33.33%;
                max-width: 33.33%;
                padding: 0 16px;
            }
            .wrapper{
                background-color: #fff;
                border-radius: 20px;
                display: block;
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;
                &:hover{
                    background-color: $yellow-20;    
                }
            }
            .news-image {
                padding-top: 50%;
                position: relative;
                img{
                    bottom: 0;
                    height: 100%;
                    left: 0;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: center;
                    object-position: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                }
            }
            .news-content{
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: 30px 25px;
                position: relative;
                .h6{
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    margin-bottom: auto;
                    @media (max-width: 767px){
                        font-weight: 400;
                    }
                }
                .news-date{
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.4;
                    margin-top: 15px;
                    text-transform: uppercase;
                    color: $low-light;
                }
                .news-cta{
                    bottom: 28px;
                    position: absolute;
                    right: 30px;
                }
            }
        }
    }
}
.media-directory-section{
    margin-bottom: 100px;
    position: relative;
    z-index: 1;
    .media-directory-grid-wrapper{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @media #{$sm}{
            margin: 0 -24px;
        }
        .member-item{
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 20px;
            margin-bottom: 40px;
            @media #{$sm}{
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 24px;
                margin-bottom: 50px;
            }
            @media #{$lg}{
                flex: 0 0 33.33%;
                max-width: 33.33%;
            }
            &:hover{
                .image-wrapper{
                    .member-bio-wrapper{
                        opacity: 1;
                    }
                }    
            }
            .image-wrapper{
                padding-top: 100%;
                border-radius: 20px;
                background-color: $gray-40;
                box-shadow: 0 0 15px rgba($black , 0.15);
                position: relative;
                overflow: hidden;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .member-bio-wrapper{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($white, 0.8);
                    color: $offBlack;
                    padding: 15px;
                    display: flex;
                    align-items: flex-end;
                    opacity: 0;
                    transition: 0.5s;
                    .wrapper{
                        font-size: 18px;
                        overflow: auto;
                        height: 100%;
                        display: flex;
                        > p{
                            margin-top: auto;
                            font-size: 16px;
                            @media #{$md}{
                                font-size: 18px;
                            }
                        }
                        &::-webkit-scrollbar-track
                        {
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                            border-radius: 8px;
                            background-color: #F5F5F5;
                        }

                        &::-webkit-scrollbar
                        {
                            width: 8px;
                            background-color: #F5F5F5;
                        }

                        &::-webkit-scrollbar-thumb
                        {
                            border-radius: 8px;
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                            background-color: #555;
                        }
                    }
                }
            }
            .members-detail {
                margin-top: 24px;
                position: relative;
                @media #{$md}{
                    margin-top: 35px;                    
                }
                .h5{
                    margin-bottom: 15px;
                    line-height: 1;
                    font-weight: 400;
                    color: $offBlack;
                    @media #{$md}{
                        margin-bottom: 20px;
                    }                        
                }
                .member-position{
                    padding-right: 40px;
                    font-size: 20px;
                    line-height: 1;
                    font-weight: 500;
                    color: $offBlack;
                }
                .icon{
                    position: absolute;
                    bottom: 0;
                    right: 6px;
                    svg{
                        height: 14px;
                        width: 14px;
                        @media #{$lg}{
                            height: 20px;
                            width: 20px;
                        }
                        fill: $offBlack;
                    }
                }
            }
        }
    }
}

.inner-banner{
    + .membership-block-section{
        margin-top: -220px;
    }
}
.membership-block-section{
    margin-bottom: 150px 0;        
    .membership-grid-wrapper{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        row-gap: 16px;
        z-index: 2;
        @media #{$md}{
            row-gap: 30px;
        }
        .membership-item{
            max-width: 100%;
            flex: 0 0 100%;
            padding: 0 15px;
            @media #{$md}{
                max-width: 50%;
                flex: 0 0 50%;
            }
            @media #{$lg}{
                max-width: 33.33%;
                flex: 0 0 33.33%;    
            }
            @media #{$xl}{
                padding: 0 25px;
            }
            &:nth-child(2){
                order: -1;
                @media #{$md}{
                    max-width: 70%;
                    flex: 0 0 70%;
                }
                @media #{$lg}{
                    transform: translateY(20px);
                    order: unset;
                    max-width: 33.33%;
                    flex: 0 0 33.33%;
                }
                .membership-title{
                    background-color: $green;
                    .h5{
                        color: $white;
                    }
                }       
                .membership-details{
                    border: 2px solid #00a196;
                    border-top: 0;
                    border-bottom-right-radius: 35px;
                    border-bottom-left-radius: 35px;
                } 
            }
        }
        .wrapper{
            background-color: #fff;
            border-radius: 35px;
            overflow: hidden;
            box-shadow: 0 0 20px rgba($black, 0.15);
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .membership-title{
            padding: 30px 0;
            text-align: center;
            .h5{
                font-size: 16px;
                text-transform: uppercase;
                color: $green;
                letter-spacing: 0.5px;
                @media #{$lg}{
                    font-size: 20px;
                }
            }
        }
        .membership-cta{
            margin: 24px 0;
            @media #{$xl}{
                margin: 50px 0;    
            }
            .btn{
                width: 100%;
            }
        }
        .membership-details{
            padding: 30px;
            padding-bottom: 0;
            flex: 1;
            @media #{$md}{
                padding: 20px;
                padding-bottom: 0;
            }
            @media #{$xl}{
                padding: 40px;
                padding-bottom: 0;
            }
        }
        .membership-pricing-details{            
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            gap: 15px;
            @media #{$md}{
                justify-content: center;
                flex-direction: column;
                text-align: center;
            }
            @media #{$xl}{
                margin-bottom: 50px;
                
            }
            .price{
                font-size: 40px;
                line-height: 1;
                color: $offBlack;
                @media #{$md}{
                    font-size: 65px
                }
                @media #{$lg}{
                    font-size: 85px;
                }
            }
            .duration{
                font-size: 14px;
                line-height: 1.4;
                color: $offBlack;
                @media #{$md}{
                    text-transform: uppercase;
                }                
            }
        }
        .membership-banefits{
            ul{
                padding: 0;
                margin: 0;
                list-style: none;
                li{
                    margin: 50px 0;
                    position: relative;
                    padding-left: 30px;
                    &:before{
                        position: absolute;
                        content: "";
                        width: 20px;
                        height: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.6 14.6L15.65 7.55L14.25 6.15L8.6 11.8L5.75 8.95L4.35 10.35L8.6 14.6ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z' fill='%2300A196'/%3E%3C/svg%3E%0A");
                    }
                    &:not(:last-child){
                        &:after{
                            position: absolute;
                            content: "";
                            width: calc(100% - 34px);
                            height: 1px;
                            background-color : $gray-40;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: -25px;
                        }
                    }
                }
            }
        }
    }   

}
.more-campaigns-section{
    margin: 80px 0;
    background-color: $gray-10;
    position: relative;
    .primary-container{
        > .content-wrapper{
            margin: 0 auto;
            max-width: 1130px;
        }
    }
    &:before{
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: -80px;
        bottom: 170px;
        background-color: $low-light-10;
        border-bottom-left-radius: 55px;
        border-bottom-right-radius: 55px;
        pointer-events: none;
        z-index: 0;
        @media (max-width: 767px){
            border-bottom-left-radius: 35px;
            border-bottom-right-radius: 35px;    
        }
    }
    &.mt-0{
        &::before{
            top: 0;
        }        
    }
    &.shape-at-bottom {
        &:before{
            top: 280px;
            bottom: -80px;
            border-top-left-radius: 55px;
            border-top-right-radius: 55px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            @media (max-width: 767px){
                border-top-left-radius: 35px;
                border-top-right-radius: 35px;    
            }
        }
    }
    &.no-shape {
        &:before{
            display: none;
        }
    }
    .section-heading{
        position: relative;
    }
    .section-content{
        overflow: hidden;
        margin: 0 -24px;
        @media #{$md}{
            overflow: unset;
            margin: 0;
        }
    }
}
.campaigns-grid-wrapper{
    position: relative;
    padding-left: 24px;
    z-index: 3;
    @media #{$md}{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
        padding: 0;
        row-gap: 40px;
    }        
    &::after{
        background: linear-gradient(90deg,rgba(244,246,246,0),#f4f6f6);
        bottom: 0;
        content: "";
        height: 100%;
        position: absolute;
        right: -24px;
        top: 0;
        width: 40px;
        @media #{$md}{
            display: none;
        }
    }
    .slick-list{
        overflow: unset;
        padding-right: 30px;
    }
    .grid-item{
        flex: 0 0 100%;
        max-width: 100%;            
        padding: 0 10px;
        @media #{$md}{
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 20px;
        }
        .wrapper{
            border-radius: 20px;
            overflow: hidden;
            background-color: $white;
            display: block;
            height: 100%;
            display: flex;
            flex-direction: column;
            box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
            &:hover{
                background-color: $yellow-20;    
            }
        }
    }
    .image-wrapper {
        padding-top: 50%;
        position: relative;
        img,iframe,video{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            object-fit: cover;
            object-position: center;
        }
    }
    .content-wrapper{
        position: relative;
        padding: 30px 25px;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        .post-category{ 
            display: flex;
            gap: 15px;
            margin-bottom: 15px;
            .category-item{
                color: #00544f;
                font-size: 12px;
                font-weight: 400;
                position: relative;
                text-transform: uppercase;
            }
        }
        .h5{
            letter-spacing: 0;
            margin-bottom: 10px;
        }
        .short-description{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
            margin-top: auto;
        }
        .news-date {
            text-transform: uppercase;
            margin-top: 15px;
            font-size: 12px;
            line-height: 1.4;
            font-weight: 400;
        }
        .news-cta{
            position: absolute;
            right: 30px;
            bottom: 28px;
        }
    }    
}
.our-partners-section{
    margin: 80px 0;
    overflow: hidden;
    @media #{$md}{
        margin: 120px 0;
    }
    
    .section-heading{
        margin-bottom: 55px;
    }
    .partners-slider-wraper{
        .our-partners{
            padding-right: 20px;
            .slick-list{
                overflow: visible;
            }
            .slick-track{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }
        .partner-item{
            padding: 0px 12px;
            @media #{$sm}{
                padding: 20px 20px;
            }
            @media #{$lg}{
               padding: 40px 40px;
            }

            img{
                object-fit: contain;
                object-position: center;
                max-height: 48px;
                max-width: 100%;
                height: 100%;
                width: 100%;
                @media #{$md}{
                    max-height: 70px;
                }
                // @media #{$xl}{
                //     max-height: 90px;
                // }
            }
        }
    }
}
.post-listing-with-pagination{
    margin: 80px 0;
    display: block;
    &.green-bg{
        background: $lightGreenGradient;
        padding: 80px 0;
        margin: 0;
    }
    &.white-bg{
        background-color: $white;
        padding: 80px 0;
        margin: 0;
    }
    .content-wrapper{
        @media #{$xl}{
            max-width: 1130px;
            margin: 0 auto;
        }
    }
}
.post-listing-wrapper{
    @media #{$xl}{
        max-width: 1130px;
        margin: 0 auto;
    }
    .post-item{        
        margin-bottom: 25px;
        padding-bottom: 25px;
        @media #{$md}{
            margin-bottom: 35px;
            border-bottom: 0;
            padding-bottom: 0;
        }
        &:not(:last-child){
            border-bottom: 1px solid $gray-20;
            @media #{$md}{
                border-bottom: 0;         
            }            
        }        
    }
    &.box-grid{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 1;
        .post-item{
            @media #{$md}{
                flex: 0 0 33.33%;
                max-width: 33.33%;
                padding: 0 16px;
            }            
        }
        .wrapper{
            flex-direction: column;
            flex-wrap: nowrap;
            background-color: #fff;
            border-radius: 20px;
            height: 100%;
            overflow: hidden;
            .post-content,
            .post-image{
                flex: unset;
                max-width: unset;
            }
            .post-content{
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: 30px 25px;
                position: relative;
                .post-details{
                    margin-bottom: 0;
                }
            }
            .post-image{
                padding-top: 50%;
                position: relative;
                img{
                    bottom: 0;
                    height: 100%;
                    left: 0;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: center;
                    object-position: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                }
                iframe{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .wrapper{
        display: flex;
        flex-wrap: wrap;
        .post-image {
            display: none;
            @media #{$md}{
                max-width: 300px;
                flex: 0 0 300px;
                display: block;
            }
            .image-wrapper{
                position: relative;
                border-radius: 20px;
                overflow: hidden;
                padding-top: 80%;
                height: 100%;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .post-content{
            display: flex;
            flex-direction: column;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 5px 22px;
            @media #{$md}{
                flex: 0 0 calc(100% - 300px);
                max-width: calc(100% - 300px);
                padding: 15px 0;
                padding-right: 0;
                padding-left: 32px;
            }
        }
        .post-category{
            margin-bottom: 25px;
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
            row-gap: 8px;
            .category-item{
                position: relative;
                &:not(:last-child){
                    &:after{
                        position: absolute;
                        content: '';
                        width: 5px;
                        height: 5px;
                        background-color: $low-light;
                        border-radius: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        left: calc(100% + 8px);
                    }
                }
            }
            span{
                font-size: 12px;
                font-weight: 400;
                color: $low-light;
            }
        }
        .post-details{
            margin-bottom: 25px;
            a{
                &:hover{
                    .h5{
                        color: $darkGreen;
                        // color: $yellow-50;
                        text-decoration: underline;
                    }        
                }
            }
            .h5{
                font-weight: 400;
                font-size: 16px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                @media #{$md}{
                    font-size: 25px;
                }
            }
            .post-description{
                display: none;
                @media #{$md}{
                    margin-top: 25px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                }
                
            }
        }
        .post-date-and-cta {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .post-date{
                font-size: 12px;
                text-transform: uppercase;
                color: $low-light;
                font-weight: 400;
            }
            .news-cta{
                height: 24px;
                width: 24px;
                padding: 6px;
                margin-left: auto;
                svg{
                    height: 12px;
                    width: 12px;
                    fill: $low-light;
                    display: block;
                }
            }
        }
    }
}
.post-pagination{
    .post-pagination{
        margin-top: 80px;    
    }
    margin-top: 80px;
}
.press-appearances-section{
    padding: 120px 0;
    background-image: $lightGreenGradient;
    overflow-x: hidden;
    @media #{$md}{
        padding: 180px 0;
    }
    .section-heading{
        margin-bottom: 50px;
        @media (max-width: 767px){
            text-align: left;
        }
    }
    .section-content{
        position: relative;
        &::after{
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            max-width: 85px;
            z-index: 1;
            pointer-events: none;
            background-image: linear-gradient(270deg, $low-light-20 0%, rgba($low-light-20, 0) 100%);
            @media #{$md}{
                max-width: 100px;
            }
            @media #{$xl}{
                max-width: 300px;
            }
        }
    }
    .scroll-slider {
        position: relative;
        // overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;
        // height: 100vh;
      }
}
.press-appearances-wrapper{
    // margin: 0 -16px;
    // position: relative;
    // display: flex;
    // flex-wrap: nowrap;
    // height: 100%;
    // padding-left: 50vw;
    // .slick-list{
    //     overflow: visible;
    //     padding-right: 120px;
    //     @media #{$sm}{
    //         padding-right: 15%;
    //     }
    // }
    .slick-list{
        overflow: visible;
        padding-right: 5%;
    }
    .press-appearance-items{
        // flex: 1 0 80vw;
        // max-width: 80vw;
        // height: 100%;
        padding: 0 8px;
        // @media #{$md}{
        //     flex: 1 0 40vw;
        //     max-width: 40vw;
        // }
        @media #{$lg}{
            padding: 0 16px;            
        }
        // @media #{$xlg}{
        //     flex: 1 0 32vw;
        //     max-width: 32vw;
        // }
        
        .image-wrapper{
            background-color: $white;
            border-radius: 15px;
            padding: 25px 10px;
            @media #{$lg}{
                padding: 40px 16px;
                border-radius: 35px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}
.has-gradient-bg +
.press-coverage-gallery-section{
    &:before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 180px;
        background-color: $low-light-20;
        border-bottom-left-radius: 35px;
        border-bottom-right-radius: 35px;
        @media #{$md}{
            height: 250px;
        }   
        @media #{$xl}{
            height: 300px;
            border-bottom-left-radius: 55px;
            border-bottom-right-radius: 55px;
        }
    }
    > .primary-container{
        @media #{$md}{
            position: relative;
        }
    }
}
.press-coverage-gallery-section{
    margin: 60px 0;
    position: relative;
    @media #{$md}{
        margin: 180px 0;    
    }
    @media (max-width: 767px){
        overflow: hidden;
    }
    
    .section-heading{
        &.with-cta{
            @media (max-width: 575px){
                .btn{
                    max-width: calc(100% - 40px);
                }
            }
        }
    }
    .coverage-grid-wrapper{    
        .slick-slide{
            height: auto;
        }    
        @media #{$md}{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -20px;
        }        
        &::after{
            background: linear-gradient(90deg,rgba(244,246,246,0),#f4f6f6);
            bottom: 0;
            content: "";
            height: 100%;
            position: absolute;
            right: -24px;
            top: 0;
            width: 40px;
            @media #{$md}{
                display: none;
            }
        }
        .slick-track{
            @media (max-width: 767px){
                display: flex;
            }
        }
        .slick-list{
            overflow: unset;
            padding-right: 30px;
        }
        .grid-item{
            // flex: 0 0 100%;
            // max-width: 100%;
            padding: 0 10px;
            @media #{$md}{
                flex: 0 0 33.33%;
                max-width: 33.33%;
                padding: 0 20px;
            }
            .wrapper{
                border-radius: 20px;
                overflow: hidden;
                background-color: $white;
                display: flex;
                flex-direction: column;
                height: 100%;
                &:hover{
                    background-color: $yellow-20;    
                }
            }
        }
        .image-wrapper {
            padding-top: 50%;
            position: relative;
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                object-fit: cover;
                object-position: center;
            }
        }
        .content-wrapper{
            position: relative;
            padding: 30px 25px;
            display: flex;
            flex: 1;
            flex-direction: column;
            .h6{
                letter-spacing: 0;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: auto;
            }
            .news-date {
                text-transform: uppercase;
                margin-top: 15px;
                font-size: 12px;
                line-height: 1.4;
                font-weight: 400;
            }
            .news-cta{
                position: absolute;
                right: 30px;
                bottom: 28px;
            }
        }
    }
}
.more-campaigns-section + .support-us-section{
    background-color: $low-light-10;
}
.get-in-touch-section + .support-us-section{
    margin-top: -120px;
    
}
.get-in-touch-section.white-bg + .support-us-section{
    background-color: $low-light-20;
}
.support-us-section{
    // z-index: 1;
    padding: 120px 0 80px;
    // background-color: $lightGreen;
    position: relative;
    // &::before{
    //     display: block;
    //     position: absolute;
    //     content: '';
    //     height: 150px;
    //     width: 100%;
    //     background-image: url(https://doctorsassociation.pixelpreview.net/wp-content/uploads/2023/11/upper-curve.png);
    //     background-position: top center;
    // }
    @media #{$md}{
        padding: 160px 0 120px;
    }
    @media #{$xl}{
        padding: 180px 0 200px;    
    }
    .bg-shape{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 1;
        img{
            object-fit: cover;
            object-position: top center;
            width: 100%;
            height: 100%;
            vertical-align: bottom;
        }
    }
    .support-content-wrapper{
        position: relative;
        z-index: 1;
        padding: 48px 35px;
        max-width: 930px;
        margin: 0 auto;
        background-color: $white;
        box-shadow: 0 0 15px rgba($black, 0.15);
        text-align: center;
        border-radius: 35px;
        @media #{$md}{
            padding: 56px;    
        }
        .h2{
            @media (max-width: 575px){
                font-size: 24px;
            }
            margin-bottom: 24px;
        }
        .content{
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0.5px;
            margin-bottom: 36px;
            @media #{$md}{
                margin-bottom: 36px;
            }            
        }
        .btn{
            width: 100%;
            @media #{$md}{
                width: auto;
            }
        }
    }
}
.inner-banner{
    + .team-members-section{
        margin-top: -55px;
        @media #{$md}{
            margin-top: -85px;
        }        
    }
}
.team-members-section{
    margin-bottom: 100px;
    position: relative;
    z-index: 1;
    .team-member-grid-wrapper{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @media #{$sm}{
            margin: 0 -24px;
        }
        .member-item{
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 20px;
            margin-bottom: 40px;
            @media #{$sm}{
                flex: 0 0 50%;
                max-width: 50%;
                padding: 0 24px;
                margin-bottom: 50px;
            }
            @media #{$lg}{
                flex: 0 0 33.33%;
                max-width: 33.33%;
            }
            &:hover{
                .image-wrapper{
                    .member-bio-wrapper{
                        opacity: 1;
                    }
                }    
            }
            .image-wrapper{
                padding-top: 100%;
                border-radius: 20px;
                background-color: $gray-40;
                box-shadow: 0 0 15px rgba($black , 0.15);
                position: relative;
                overflow: hidden;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .member-bio-wrapper{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($white, 0.8);
                    color: $offBlack;
                    padding: 15px;
                    display: flex;
                    align-items: flex-end;
                    opacity: 0;
                    transition: 0.5s;
                    .wrapper{
                        font-size: 18px;
                        overflow: auto;
                        height: 100%;
                        display: flex;
                        > p{
                            margin-top: auto;
                            font-size: 16px;
                            @media #{$md}{
                                font-size: 18px;
                            }
                        }
                        &::-webkit-scrollbar-track
                        {
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                            border-radius: 8px;
                            background-color: #F5F5F5;
                        }

                        &::-webkit-scrollbar
                        {
                            width: 8px;
                            background-color: #F5F5F5;
                        }

                        &::-webkit-scrollbar-thumb
                        {
                            border-radius: 8px;
                            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                            background-color: #555;
                        }
                    }
                }
            }
            .members-detail {
                margin-top: 24px;
                position: relative;
                @media #{$md}{
                    margin-top: 35px;                    
                }
                .h5{
                    margin-bottom: 15px;
                    line-height: 1;
                    font-weight: 400;
                    color: $offBlack;
                    @media #{$md}{
                        margin-bottom: 20px;
                    }                        
                }
                .member-position{
                    padding-right: 40px;
                    font-size: 20px;
                    line-height: 1;
                    font-weight: 500;
                    color: $offBlack;
                }
                .icon{
                    position: absolute;
                    bottom: 0;
                    right: 6px;
                    svg{
                        height: 14px;
                        width: 14px;
                        @media #{$lg}{
                            height: 20px;
                            width: 20px;
                        }
                        fill: $offBlack;
                    }
                }
            }
        }
    }
}

.tv-appearances-section{
    margin: 80px 0;
    overflow-x: hidden;
    .primary-container{
        @media #{$xl}{
            padding-left: 85px;
            padding-right: 85px;
        }
    }
    .section-heading{
        @media (max-width: 767px){
            text-align: left;
        }
    }
}
.tv-appearances-grid-wrapper{
    position: relative;
    @media #{$md}{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -24px;
    }        
    &::after{
        background: linear-gradient(90deg,rgba(244,246,246,0),#f4f6f6);
        bottom: 0;
        content: "";
        height: 100%;
        position: absolute;
        right: -24px;
        top: 0;
        width: 40px;
        @media #{$md}{
            display: none;
        }
    }
    .slick-list{
        overflow: unset;
        padding-right: 30px;
    }
    .grid-item{
        flex: 0 0 100%;
        max-width: 100%;            
        padding: 0 10px;
        @media #{$md}{
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 24px;
        }
        .appearance-image{
            position: relative;
            .image-wrapper{
                border-radius: 16px;
                overflow: hidden;
                padding-top: 70%;
                position: relative;
                &:after{
                    position: absolute;
                    content: '';
                    background-color: rgba($black, 0.35);
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                img,
                iframe{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border: 0;
                }
            }
            .play-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                svg{
                    fill: $white;
                    height: 40px;
                    width: 40px;
                }
            }
        }
        .appearance-details{
            padding-top: 25px;
            .h5{
                font-weight: 400;
                margin-bottom: 25px;
            }
            .date-category-wrapper{
                display: flex;
                justify-content: space-between;
                .category{
                    span{
                        font-size: 12px;
                        color: $low-light;
                        font-weight: 400;
                    }
                }
                .date{
                    font-size: 12px;
                    font-weight: 400;
                    color: $low-light;
                }
            }
        }
    }
}
.single-page-banner{
    &.inner-banner{
        .left-part{
            min-height: auto;
            .post-date{
                color: $white;
                font-size: 20px;
                margin-bottom: 30px;
                line-height: 1.2;
            }
            .banner-heading{
                font-size: 39px;
            }
        }    
        .right-part {
            .image-wrapper{
                position: relative;
                // @media #{$md}{
                    // margin-bottom: -120px;
                // }
                
            }
        }
        &.tv-appearance-banner-wrapper{
            margin-bottom: 30%;
            @media #{$xl}{
                margin-bottom: 280px;
            }            
            &:before{
                height: 100%;
            }
            .left-part{
                width: 100%;
                min-height: auto;
                margin-bottom: 0;
                .wrapper{
                    padding-bottom: 50px;
                    max-width: 100%;
                    text-align: center;
                    .banner-heading{
                        margin: 0;
                        font-size: 36px;
                        @media #{$sm}{
                            font-size: 45px;
                        }
                        @media #{$lg}{
                            font-size: 50px;
                        }
                        @media #{$xl}{
                            font-size: 61px;
                        }
                    }
                }
            }
            .right-part {
                width: 100%;
                padding: 0 15px;
                max-width: 920px;
                
                .image-wrapper{
                    margin-bottom: -30%;
                    position: relative;
                    @media #{$sm}{
                        padding-top: 62%;
                    }
                    &:after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: rgba($black, 0.35);
                        pointer-events: none;
                    }
                    iframe{
                        border: 0;
                        height: 100%;
                        left: 0;
                        -o-object-fit: cover;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        width: 100%;
                    }
                }
            }
            + .single-post-content{
                margin-top: 0;
            }
        }
        &.event-detail-page-banner{
            margin-bottom: 30%;
            @media #{$xl}{
                margin-bottom: 280px;
            }            
            &:before{
                height: 100%;
            }
            .left-part{
                width: 100%;
                min-height: auto;
                margin-bottom: 0;
                .wrapper{
                    padding-bottom: 50px;
                    max-width: 100%;
                    text-align: center;
                    .banner-heading{
                        margin: 0;
                        font-size: 36px;
                        @media #{$sm}{
                            font-size: 45px;
                        }
                        @media #{$lg}{
                            font-size: 50px;
                        }
                        @media #{$xl}{
                            font-size: 61px;
                        }
                    }
                }
            }
            .right-part {
                width: 100%;
                padding: 0 15px;
                max-width: 920px;
                
                .image-wrapper{
                    // margin-bottom: -30%;
                    position: relative;
                    @media #{$sm}{
                        padding-top: 62%;
                    }
                    &:after{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: rgba($black, 0.35);
                        pointer-events: none;
                        opacity: 0;
                    }
                    iframe{
                        border: 0;
                        height: 100%;
                        left: 0;
                        -o-object-fit: cover;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        width: 100%;
                    }
                }
            }
            + .single-post-content{
                margin-top: 0;
            }
        }
    }
}
.single-post-content{
    margin: 80px 0;
    position: relative;
    @media #{$md}{
        margin-top: -110px;
    }    
    &.tv-appearance-post{
        .content-wrapper {
            .left-part{
                max-width: 920px;
                width: 100%;
                padding: 0 15px;
                margin: 0 auto;
            }
        }
    }
    .post-details{
        margin-bottom: 35px;
        @media #{$lg}{
            margin-bottom: 65px
        }
        .user-details{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 30px;
            @media #{$lg}{
                margin-bottom: 50px;
            }
            .user-img{
                width : 35px;
                @media #{$md}{
                    width : 50px;
                }                
                margin-right: 35px;
                .image-wrapper{
                    padding-top: 100%;
                    border-radius: 50%;
                    overflow: hidden;
                    position: relative;
                    img{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        top: 0;
                        left: 0;
                    }
                }
            }
            .user-name{
                font-size: 16px;
                color: $offBlack;
                line-height: 1.4;
                font-weight: 500;
                @media #{$md}{
                    font-size: 20px;    
                }
            }
        }
        .category-publish-date{
            display: flex;
            flex-wrap: wrap;
            gap: 100px;
            row-gap: 40px;
            > div:not(:first-child){
                position: relative;
                &:before{
                    position: absolute;
                    content: "";
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    border: 1px solid $low-light;
                    left: -54px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            .tags{
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                gap : 15px;
                .tag{
                    font-size: 16px;
                    color: $low-light;
                    font-weight: 500;
                    position: relative;
                    @media #{$md}{
                        font-size: 20px;
                    }
                    &:not(:last-child){
                        &:after{
                            position: absolute;
                            content: '|';
                            color : $low-light;     
                            font-weight: 500;
                            right: -8px;
                            width: 2px;
                            text-align: center;
                            font-size: 16px;
                            @media #{$md}{
                                font-size: 20px;
                            }
                        }
                    }
                }                
            }
            .publish-before{
                span{
                    font-size: 16px;
                    color: $low-light;
                    font-weight: 500;
                    @media #{$md}{
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .post-content-wrapper{
        position: relative;
        a   {
            text-decoration: underline;
            &:hover{
                color: $green;
            }
        }
    }
    .content-wrapper{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .left-part{
            width: 100%;
            @media #{$md}{
                width: calc(100% - 300px);
            }
            @media #{$lg}{
                width: 70%;
            }
            .detail-content{
                @media #{$lg}{
                    column-count: 2;
                    column-gap: 35px;
                }
                @media #{$xl}{
                    column-gap: 70px;
                }
                > div, figure{
                    column-span: all;
                }
            }
        }
        .right-part{
            position: relative;
            order: -1;
            max-width: 100%;
            padding: 0;
            width: 100%;
            @media #{$md}{
                padding-left: 30px;
                position: absolute;
                bottom: 0;
                max-width: 300px;
                right: 0;
                top: 0;                
                order: unset;
            }
        }
        p, figure{
            font-size: 18px;
            line-height: 1.2;
            margin-bottom: 30px;            
            &:has(iframe){
                column-span: all;
                iframe{
                    max-width: 100%;
                }
            }
            &:has(img){
                column-span: all;
                @media (max-width: 767px){
                    position: relative;
                    padding-top: 100%;
                    border-radius: 35px;
                    overflow: hidden;
                    img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                        object-position: center;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        img{
            width: 100%;
            @media #{$lg}{
                margin: 60px 0;
            }
        }
        .blog-menu{
            padding: 60px 47px;
            box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
            position: sticky; 
            position: -webkit-sticky;
            top: 80px;
            border-top-left-radius: 35px;
            border-bottom-left-radius: 35px;
            @media ( max-width: 767px){
                width: 100%;
                max-width: 100%;
                position: static;
                border-radius: 0;
                box-shadow: none;
                margin-bottom: 30px;
                padding: 10px 30px;
            }
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                @media (max-width: 767px){
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }
                li{
                  &:not(:last-child)  {
                    margin-bottom: 50px;
                    @media (max-width: 767px){
                        margin-bottom: 0px;
                    }
                  }
                  @media (max-width: 767px){
                    padding: 10px;
                  }
                }
                .wrapper{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    font-size: 12px;
                    text-transform: uppercase;
                    .icon-wrapper{
                        width: 24px;
                        height: 24px;
                        margin-right: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @media #{$md}{
                            margin-right: 25px;
                        }
                        svg{
                            height: 20px;
                            width: 20px;
                            fill: $offBlack;
                        }
                    }
                    &:hover{
                        color: $green;
                        svg{
                            fill: $green;
                        }
                    }
                }
            }
        }
    }
}
.more-news-section{
    margin: 100px 0;
    overflow: hidden;
    @media #{$md}{
        overflow: unset;
    }
    .news-slider-wrapper{
        position: relative;
        z-index: 2;
        @media #{$md}{
            display: flex;
            flex-wrap: wrap;
            margin: 0 -16px;
        }
        &:after{
            background: linear-gradient(90deg,rgba(244,246,246,0),#f4f6f6);
            bottom: 0;
            content: "";
            height: 100%;
            position: absolute;
            right: -24px;
            top: 0;
            width: 40px;
            pointer-events: none;
            @media #{$md}{
                display: none;
            }
        }
        .slick-list{
            overflow: visible;
            padding-right: 50px;
        }
        .news-item{
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 10px;
            @media #{$md}{
                flex: 0 0 33.33%;
                max-width: 33.33%;
                padding: 0 16px;
            }
            .wrapper{
                background-color: #fff;
                border-radius: 20px;
                display: block;
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;
                &:hover{
                    background-color: $yellow-20;    
                }
            }
            .news-image {
                padding-top: 50%;
                position: relative;
                img{
                    bottom: 0;
                    height: 100%;
                    left: 0;
                    -o-object-fit: cover;
                    object-fit: cover;
                    -o-object-position: center;
                    object-position: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 100%;
                }
            }
            .news-content{
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: 30px 25px;
                position: relative;
                .h6{
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    line-height: 1.3;
                    @media (max-width:767px){
                        font-weight: 400;
                    }
                }
                .news-date{
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.4;
                    margin-top: 15px;
                    text-transform: uppercase;
                    color: $low-light;
                    letter-spacing: 0.5px;
                }
                .news-cta{
                    bottom: 28px;
                    position: absolute;
                    right: 30px;
                }
            }
        }
    }
    + .get-involve-section{
        margin-top: -240px;
        padding-top: 250px;
        // border-top-right-radius: 55px;
        // border-top-left-radius: 55px;
        @media #{$md}{
            margin-top: -280px;
            padding-top: 350px;
            border-top-right-radius: 55px;
            border-top-left-radius: 55px;
        }
        @media #{$xl}{
            padding-top: 400px;
        }
        .bg-shape-wrapper { 
            max-width: 60%;
            img{
                object-fit: contain;
                object-position: left bottom;
                height: 90%;
                top: auto;
                bottom: 0;
            }
        }
        &.content-left{
            .bg-shape-wrapper { 
                img{
                    @media #{$md}{
                        object-fit: contain;                    
                        object-position: right bottom;
                    }
                }
            }    
        }
    }    
}
.comments-section-wrapper{
    margin: 50px 0;
    .commentlist,
    .comment-list{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 70px;
        margin-bottom: 65px;
        @media #{$lg}{
            margin-bottom: 80px;
        }
        li{
            position: relative;
            &:not(:last-child){
                &:after{
                    position: absolute;
                    content: '';
                    width: calc(100% - 50px );
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -35px;
                    height: 1px;
                    background-color: $gray-30;
                }
            }
            
            .comment-body{
                padding-left: 95px;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                @media (max-width: 991px){
                    padding-left: 65px;
                }
                @media (max-width: 767px){
                    padding-left: 65px;
                }
                .comment-content{
                    width: 100%;
                    order: -1;                    
                    p{
                        font-size: 14px;
                        line-height: 1.4;
                        color: $offBlack;
                        margin-bottom: 20px;
                    }
                }
                .comment-meta{
                    width: 100%;
                    .comment-metadata{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        @media (max-width: 991px){
                            flex-direction: column;
                            gap: 10px;
                            align-items: flex-start;
                        }
                        .fn,.date{
                            color: $green;
                            font-size: 12px;
                            line-height: 1.2;
                            text-transform: uppercase;
                            font-style: normal;
                            font-weight: 500;
                        }
                        .date{                            
                            color: $low-light-30;
                            @media #{$lg}{
                                margin-left: auto;
                                color: $gray-80;
                            }

                        }
                        .comment-edit-link{
                            color: $green;
                            font-size: 12px;
                            line-height: 1.2;
                            text-transform: uppercase;
                            font-style: normal;
                            font-weight: 500;
                            @media #{$lg}{
                                margin-left: 10px;
                            }
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .comment-author{
                position: absolute;
                left : 0;
                top : 0;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                overflow: hidden;
                @media (max-width: 991px){
                    width: 40px;
                    height: 40px;
                }
                @media (max-width: 767px){
                    width: 25px;
                    height: 25px;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    margin: 0;
                }
                .fn,
                .says{
                    display: none;
                }
            }
        }
    }
    .form-content-wrapper{
        background-color: $white;
        box-shadow: 0 0 20px rgba($black, 0.15);
        border-radius: 25px;
        // padding: 35px;
        padding: 48px 32px;
        @media #{$lg}{
            padding: 50px;
            border-radius: 35px;
        }
        
        h3#comments,
        h2.comments-title{
            display: none;
        }
        .comment-respond{
            .comment-form{
                position: relative;
                display: flex;
                flex-direction: column;
            }
            .comment-reply-title,
            .logged-in-as,
            .comment-notes{
                display: none;
            }
            .comment-form-comment{
                background-color: $gray-20;
                border-radius: 20px;
                margin-bottom: 0;
                padding-right: 65px;
                label{
                    display: none;
                }
                textarea{
                    background-color: transparent;
                    border: 0;
                    outline: none;
                    width: 100% !important;
                    min-height: 65px !important;
                    height: 65px;
                    max-height: 180px !important;
                    padding: 15px 20px;
                }
            }
            .comment-form-author,
            .comment-form-email,
            .comment-form-url{
                margin: 0;
                margin-bottom: 20px;
                order: -1;
                label{
                    font-size: 16px;
                    line-height: 1.2;
                    color: $offBlack;
                    margin-bottom: 10px;
                    display: block;
                }
                input{
                    border: 1px solid $offBlack;
                    height : 50px;
                    padding: 0 20px;
                    width: 100%;
                    outline: none;
                    border-radius: 10px;
                }
            }
            .comment-form-cookies-consent{
                order: -1;
                display: flex;
                align-items: flex-start;
                input[type="checkbox"]{
                    width: 20px;
                    height: 20px;
                    border: 1px solid #1f1f1f;
                    &:checked{
						background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.0501 13L0.350098 7.30001L1.7751 5.87501L6.0501 10.15L15.2251 0.975006L16.6501 2.40001L6.0501 13Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
						background-position: center;
						background-repeat: no-repeat;
                        background-size: 14px;
					}
                }
                label{
                    max-width: calc(100% - 35px);
                    margin-left: auto;
                    width: 100%;
                }
            }
            .form-submit {
                position: absolute;
                bottom: 15px;
                right: 10px;
                margin: 0;
                height: 35px;
                width: 35px;
                border-radius: 50%;
                font-size: 0;
                background-position: center;
                background-size: 25px;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_704_1858' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='35' height='35'%3E%3Crect width='35' height='35' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_704_1858)'%3E%3Cpath d='M4.375 29.1666V5.83325L32.0833 17.4999L4.375 29.1666ZM7.29167 24.7916L24.5729 17.4999L7.29167 10.2083V15.3124L16.0417 17.4999L7.29167 19.6874V24.7916Z' fill='%23858C8C'/%3E%3C/g%3E%3C/svg%3E%0A");
                cursor: pointer;
                &:hover{
                    background-color: $gray-40;
                }
                input{
                    height: 35px;
                    width: 35px;    
                    font-size: 0;
                    background-color: transparent;
                    border: 0;
                    outline: none;
                    cursor: pointer;
                }
            }
        }
    }
    .comment-heading{
        margin-bottom: 50px;
    }
}
    

.get-involve-section{
    &.single-detailpage{
        margin-bottom : 0;
    }
}
.blog-filter{

    padding: 50px 15px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
    row-gap: 10px;
    #search-blog{
        background-color: #fff;
        border: 1px solid #1f1f1f;
        border-radius: 10px;
        font-size: 16px;
        height: 50px;
        line-height: 1.2!important;
        margin-bottom: 15px;
        outline: none;
        padding: 8px 14px;
        width: 100%; 
        max-width: 500px;
    }
    .category-filter-dropdown{
        background-color: #fff;
        border: 1px solid #1f1f1f;
        border-radius: 10px;
        font-size: 16px;
        height: 50px;
        line-height: 1.2!important;
        margin-bottom: 15px;
        outline: none;
        padding: 8px 14px;
        padding-right: 50px;
        width: 100%; 
        max-width: 300px;
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='10' viewBox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.10811 10L0 1.89189L1.89189 0L8.10811 6.21622L14.3243 0L16.2162 1.89189L8.10811 10Z' fill='%231C1B1F'/%3E%3C/svg%3E%0A");
        background-size: 14px;
        background-position: 94%;
        background-repeat: no-repeat;
        outline: none;
    }
}

.cdp{
    .cdp{
        &_i{
            display: none !important;
            &:first-child,
            &:last-child {
                display: flex !important;
            }

            &:last-child,
            &:nth-child(1){
            display: flex !important;
            }
        }
    }
}
@for $i from 1 through 80 {
    .cdp[actpage="#{$i}"] {
      // 3 before
      .cdp_i:nth-child(#{$i - 1}):not(:first-child):not(:nth-child(2)) {
        display: flex !important;
        pointer-events: none;
        color: transparent;
        border-color: transparent;
        width: 50px;
        &:after {
          content: "...";
          color: #fff;
          font-size: 32px;
          margin-left: -6px;
        }
      }
      // 2 before
      .cdp_i:nth-child(#{$i - 1}):not(:first-child) {
        display: flex !important;
      }
      // before
      .cdp_i:nth-child(#{$i}):not(:first-child) {
        display: flex !important;
      }
      // active
      .cdp_i:nth-child(#{$i}) {
        display: flex !important;  
        + .cdp_i:last-child {
          display: none !important;
        }
      }
      // next
      .cdp_i:nth-child(#{$i + 1}):not(:last-child) {
        display: flex !important;
      }
      // 2 next
      .cdp_i:nth-child(#{$i + 2}):not(:last-child) {
        display: flex !important;
      }
      // 3 next
      .cdp_i:nth-child(#{$i + 3}):not(:last-child):not(:nth-last-child(2)) {
        display: flex !important;
        pointer-events: none;
        color: transparent;
        border-color: transparent;
        width: 50px;
        &:after {
          content: "...";
          color: #fff;
          font-size: 32px;
          margin-left: -6px;
        }
      }
    }
  }
.footer {
	background: #282a2a;

	&-wrapper {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 90px 0 75px;

		@media #{$md} {
			padding: 55px 0 75px;
		}
		@media #{$large} {
			padding: 75px 0 115px;
		}
	}
	&--main {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		flex-direction: column-reverse;
		flex-wrap: wrap;
		margin-bottom: 30px;
		width: 100%;

		@media #{$md} {
			flex-direction: row;
			margin-bottom: 50px;
		}
		@media #{$lg} {
			width: auto;
			margin-bottom: 0;
		}
		&-details {
			width: 100%;
			margin-top: 100px;

			@media #{$md} {
				width: auto;
				margin-top: 0;
			}
			img {
				height: auto;
				width: 215px;

				@media #{$large} {
					width: 245px;
				}
			}
			aside {
				margin: 35px 0;

				p {
					color: $white;
					line-height: 1.5;
					font-size: 18px;
				}
			}
			.socials {
				p {
					color: $white;
					font-size: 18px;
					margin-bottom: 25px;

					@media #{$large} {
						margin-bottom: 35px;
					}
				}
				&-icons {
					display: flex;
					align-items: center;

					a {
						transition: $ease;
						transform: scale(1);						
						svg {
							height: 20px;
							width: 20px;
							fill : $white;
							@media #{$large} {
								height: 22px;
								width: 22px;
							}
							@media #{$extra} {
								height: 24px;
								width: 24px;
							}
						}
						
						i {
							color: $white;
							transition: $ease;
							font-size: 20px;

							@media #{$large} {
								font-size: 22px;
							}
							@media #{$extra} {
								font-size: 24px;
							}
						}
						&:not(:last-of-type) {
							margin-right: 35px;

							@media #{$large} {
								margin-right: 50px;
							}
						}
						&:hover {
							transform: scale(1.1);

							svg {
								fill: $lightGreen;
							}
						}
					}
				}
			}
		}
		.seperator {
			display: none;

			@media #{$md} {
				display: block;
				padding: 0 100px;
			}
			@media #{$lg} {
				padding: 0 55px;
			}
			@media #{$xl} {
				padding: 0 75px;
			}
			@media #{$large} {
				padding: 15px 100px;
			}
			span {
				display: block;
				height: 100%;
				width: 1px;
				background: $white;
			}
		}
		&-links {
			width: 100%;

			@media #{$md} {
				width: auto;
			}
			nav {
				ul {
					padding: 0;
					list-style: none;

					li {
						a {
							color: $white;
							text-transform: uppercase;
							border-bottom: 1px solid transparent;
							font-weight: 600;
							letter-spacing: 0.35px;
							padding-bottom: 2px;
							font-size: 14px;

							&:hover {
								border-bottom-color: $white;
							}
						}
						&:not(:last-of-type) {
							margin-bottom: 45px;

							@media #{$md} {
								margin-bottom: 30px;
							}
							@media #{$large} {
								margin-bottom: 45px;
							}
						}
					}
				}
			}
		}
	}
	&--copyright{
		.copyright {
			color: $white;
			font-size: 14px;
		}
		.pixel-kicks{			
			margin-top: 15px;
			@media #{$lg}{
				text-align: right;
			}
			.signature{
				img{
					filter: brightness(0) invert(1);
					max-width: 250px;
					width: 100%;
				}
			}
		}
	}
	
}

.header {
	&--top {
		display: none;

		@media #{$lg} {
			display: block;
			background: $darkGreen;
		}
		&-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 15px 0;

			// @media #{$xl} {
			// 	padding: 20px 0;
			// }
			// @media #{$large} {
			// 	padding: 25px 0;
			// }
			.socials-icons {
				display: flex;
				align-items: center;
				margin-right: auto;
				a {
					transition: $ease;
					transform: scale(1);						
					svg {
						height: 20px;
						width: 20px;
						fill : $white;
					}
					&:not(:last-of-type) {
						margin-right: 20px;
					}
					&:hover {
						transform: scale(1.1);

						svg {
							fill: $yellow;
						}
					}
				}
			}
			a {
				display: flex;
				align-items: center;
				&:hover{
					p {
						color: $yellow;
						span {
							color: $white;
						}
					}
				}
				p {
					color: $white;
					font-weight: 600;
					font-size: 14px;
					margin-right: 20px;
					@media #{$large} {
						font-size: 14px;
						margin-right: 25px;
					}
					span {
						margin-right: 15px;
					}
				}
				svg {
					height: auto;
					width: 16px;

					@media #{$large} {
						width: 20px;
					}
				}
			}
		}
	}
	&--main {
		background: $lightGreen;
		background: $greenGradient;
		@media #{$lg} {
			background: $greenGradient;
		}
		&-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 25px 0;
			// @media #{$large} {
			// 	padding: 50px 0;
			// }
			.logo {
				img {
					height: auto;
					width: 110px;

					@media #{$sm} {
						width: 125px;
					}
					@media #{$md} {
						width: 135px;
					}
					@media #{$xl} {
						width: 155px;
					}
					@media #{$large} {
						width: 170px;
					}
					// @media #{$extra} {
					// 	width: 200px;
					// }
				}
			}
			
			.header--main-links {
				display: flex;
				align-items: center;
				position: relative;
				nav {
					display: none;
					@media (max-width: 991px){
						background-color: $white;
						display: block;
						max-width: 350px;
						position: fixed;
						width: 100%;
						top: 0;
						bottom: 0;
						z-index: 999999;
						right: -350px;
						transition: 0.5s;
						padding: 20px;
						padding-top: 120px;
						height: 100%;
						overflow-y: auto;
					}
					@media #{$lg} {
						display: block;
						border-right: 2px solid $white;
						padding-right: 30px;
					}
					@media #{$xlg} {
						padding-right: 50px;
					}
					// @media #{$extra} {
					// 	border-right: 3px solid $white;
					// 	padding-right: 65px;
					// }
					> ul{
						display: flex;
						align-items: center;
						> li{
							padding-top: 10px;
							padding-bottom: 10px;
						}						
					}
					ul {						
						padding: 0;
						margin: 0;
						list-style: none;
						@media (max-width: 991px){
							flex-direction: column;							
							align-items: flex-start;
						}
						li {
							&:hover{
								a {
									color: $green;
									@media #{$md}{
										color: $yellow;
									}
								}
							}
							a {
								letter-spacing: 0.35px;
								text-transform: uppercase;
								font-weight: 600;
								color: $offBlack;
								font-size: 14px;
								padding: 16px 40px;
								display: block;
								@media #{$lg} {
									color: $white;
									padding: 0;
								}
								// @media #{$extra} {
								// 	font-size: 16px;
								// }
								
							}
							&:not(:last-of-type) {
								@media#{$lg} {
									margin-right: 20px;
								}
								@media #{$xl} {
									margin-right: 40px;
								}
								// @media #{$extra} {
								// 	margin-right: 100px;
								// }
							}
							&.current-page-ancestor,
							&.current-menu-item{
								a{
									color: $green;
									@media #{$lg} {
										color: $yellow;
									}
								}
							}
							&.menu-item-has-children{
								position: relative;
								width: max-content;
								> a {
									display: inline-block;
									width: auto;
								}
								&:hover{
									> ul{
										opacity: 1;
										visibility: visible;
										
									}
								}
								&.active{
									> span.toggle-sub-menu{
										transform: scaleY(-1);
									}	
								}
								> span.toggle-sub-menu{
									position: absolute;
									top: 20px;
									height: 20px;
									width: 20px;
									right: 0;
									background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' fill='none' viewBox='0 0 12 7'%3E%3Cpath fill='%23111' d='M6 6.708.692 1.4 1.4.692l4.6 4.6 4.6-4.6.708.708z'/%3E%3C/svg%3E");
									background-position: center;
									background-repeat: no-repeat;
									background-size: 80%;
									transition: 0.5s;
								}
								> ul{
									position: absolute;
									top : 100%;
									left: -15px;
									background-color: $white;
									z-index: 99;
									min-width: 180px;
									opacity: 0;
									visibility: hidden;
									padding: 20px;
									border-bottom-left-radius: 15px;
									border-bottom-right-radius: 15px;
									@media (max-width: 991px){
										position: relative;
										display: none;
										padding: 0px;
										left: 0;
										padding-left: 15px;
									}
									&:before{
										position: absolute;
										content: '';
										height: 15px;
										width: 15px;
										background-color: $white;
										top: -4px;
										left: 25px;
										transform: rotate(45deg);
									}
									li:not(:last-child){
										margin-bottom: 10px;
										@media (max-width: 991px){
											margin-bottom: 0px;
										}
									}
									a{
										color: $primary;
										&:hover{
											color: $black;
										}
										@media (max-width: 991px){
											color: $black;
											&:hover{
												color: $primary;
											}	
										}
									}

								}
							}
						}
					}
					.user-login-btn,
					.user-account-btn{
						.icon-btn{
							color: $offBlack;
							padding: 16px 40px;
							svg{
								margin-left: 8px;
								width: 18px;
								height: 18px;
								path{
									fill: $offBlack;
								}
							}
						}
						@media #{$lg}{
							display: none;
						}
					}
					
				}
				.icons {
					display: flex;
					align-items: center;
					z-index: 999999;
					@media #{$lg} {
						padding-left: 30px;
					}
					@media #{$xlg}{
						padding-left: 50px;
					}
					// @media #{$extra} {
					// 	padding-left: 65px;
					// }
					svg {
						width: 25px;
					}
					.mobile-toggle {
						cursor: pointer;
						border: none;
						background: transparent;
						margin-left: 20px;
						z-index: 2;
						@media #{$lg} {
							display: none;
						}
					}
					.header-search{
						display: flex;
						align-items: center;
						position: relative;
						@media #{$md}{
							position: static;
						}						
						&:hover{
							svg{
								path{
									fill: $yellow;
								}
							}
							.search-input{
								border-color: $yellow;
							}
						}
						.search-icon{
							position: relative;
							z-index: 2;
						}
						svg{
							height: 18px;
							width:20px;
						}						
						.search-input{
							position: absolute;
							right: 0;
							border: 0;
							background: transparent;
							border-bottom: 2px solid transparent;
							height: 32px;
							top: 50%;
							transform: translateY(-50%);
							transition: 0.5s;
							outline: none;
							color: #fff;
							width: 20px;
							padding: 0;
							opacity: 0;
							visibility: hidden;
							@media #{$md}{
								height: 42px;
							}
							&::placeholder{
								color: $white;
							}
						}
						&.active-search{
							.search-input{
								border-color: $yellow;
								width: 150px;
								padding-right: 30px;
								padding-left: 5px;
								opacity: 1;
								visibility: visible;
								@media #{$md}{
									width: 100%;
									background-image: $greenGradient;
									padding-left: 10px;
									
								}
							}
							#searchform{
								width: 100%;
								input[type="text"]{
									border-color: $yellow;
									width: 150px;
									padding-right: 30px;
									padding-left: 5px;
									opacity: 1;
									visibility: visible;
									@media #{$md}{
										width: 100%;
										background-image: $greenGradient;
										padding-left: 10px;
										
									}
								}
								// input[type="submit"]{
								// 	pointer-events: unset;
								// }
							}
						}
						#searchform{
							position: absolute;
							right: 0;
							border: 0;
							width: 20px;
							transition: 0.5s;
							label{
								display: none;
							}
							input[type="text"]{
								position: absolute;
								right: 0;
								border: 0;
								background: transparent;
								border-bottom: 2px solid transparent;
								height: 32px;
								top: 50%;
								transform: translateY(-50%);
								transition: 0.5s;
								outline: none;
								color: #fff;
								width: 20px;
								padding: 0;
								opacity: 0;
								visibility: hidden;
								@media #{$md}{
									height: 42px;
								}
								&::placeholder{
									color: $white;
								}
							}
							input[type="submit"]{
								font-size: 0;
								border: 0;
								height: 0px;
								width: 0px;
								background-color: transparent;
							}
						}
					}
					.menu-icon{
						height: 24px;
						width: 24px;
						display: flex;
						justify-content: center;
						flex-direction: column;
						flex-wrap: wrap;
						gap: 4px;
						span{
							width: 18px;
							height: 2px;
							background-color: $white;
							display: block;
							margin: 0px auto;
							-webkit-transition: all 0.3s ease-in-out;
							-o-transition: all 0.3s ease-in-out;
							transition: all 0.3s ease-in-out;
						}
						&.active{
							span{
								background-color: $green;
								transition: 0.5s;
								&:nth-child(1){
									-webkit-transform: translateY(6px) rotate(45deg);
									-ms-transform: translateY(6px) rotate(45deg);
									-o-transform: translateY(6px) rotate(45deg);
									transform: translateY(6px) rotate(45deg);
								}
								&:nth-child(2){
									opacity: 0;
								}
								&:nth-child(3){
									-webkit-transform: translateY(-6px) rotate(-45deg);
									-ms-transform: translateY(-6px) rotate(-45deg);
									-o-transform: translateY(-6px) rotate(-45deg);
									transform: translateY(-6px) rotate(-45deg);
								}
							}
						}
					}
				}
			}
		}
	}
}

.menu-open{
	overflow: hidden;
	.header{
		&--main{
		&-wrapper {
			.header--main-links{
					nav{
						right: 0;
					}
					.search-icon{
						opacity: 0;
					}
					.mobile-toggle{
						z-index: 3;
					}
				}
			}
		}
	}
}